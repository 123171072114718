@import 'customer-authentication/index';

.account {
    .page-main {
        @import 'layout.scss';

        @import 'customer-account-navigation';

        @import 'customer-table';
        @import 'customer-table-toolbar';

        .page-title-wrapper {
            display: none;
        }

        .actions-toolbar {
            padding-top: 1rem;
            .back {
                @include button-lybe($style: hollow);
            }
        }
    }
}

.customer-account-index {
    @import 'customer-dashboard/index';
}

.sales-order-history {
    @import 'customer-my-orders/index';
}

.sales-order-view {
    @import 'customer-my-orders-details/index';
}

.customer-account-edit {
    @import 'customer-edit/index';
}

@import 'customer-address/index';
