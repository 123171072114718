// footer
footer {
    .footer-navigation-wrapper {
        @extend .grid-x, .grid-margin-x;

        .footer-navigation,
        .footer-contact-info {
            @extend .small-12, .medium-6, .cell;
        }

        .accordion-menu {
            @extend .grid-x;

            li {
                @extend .medium-4;
            }
        }

    }
}

.footer-navigation-desktop {
    ul {
        list-style: none;
        margin: 0;
    }
}

.footer-navigation-desktop__headline {}
