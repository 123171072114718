.page-footer {
    background: $footer-bg;
    padding: 0;
    color: $white;
    font-size: 13px;

    @include breakpoint(medium up) {
        padding-bottom: 40px;

        .accordion-menu {
            border-top: none;

            li {
                a {
                    margin-bottom: 0.75rem;
                    padding: 0;
                    border: none;
                }

                .menu {
                    margin-left: 0;
                }
            }
        }
    }

    .footer .small {
        font-weight: bold;
        font-size: 12px;

        a {
            color: $white;
        }
    }

    .footer {
        padding-top: 5px;

        .accordion {
            border-top: none;
        }

        .block {
            @include xy-gutters($negative: true);
            border-bottom: 1px solid $footer-border-color;
            font-size: rem-calc(14);

            .title {
                @include xy-gutters($gutter-type: padding);
                display: block;
                font-size: rem-calc(24);
                font-weight: $global-weight-bold;
                margin-bottom: 0;
                padding-bottom: 8px;
                padding-top: 13px;
                position: relative;

                strong {
                    font-weight: $global-weight-bold;
                }
            }

            .content {
                @include xy-gutters($gutter-type: padding);
                padding-bottom: 10px;

                ul {
                    li {
                        line-height: 2.5;
                    }
                }
            }

            .highlight {
                background: get-color(primaryDark);
                padding: 10px 15px;
                position: relative;

                &:before {
                    @include scout-icon($icon: $icon-star, $fontSize: 14px);
                    color: $color-orange;
                    left: 0;
                    position: absolute;
                    top: 0;
                    transform: translate(-50%, -50%);
                }
            }
        }

        &-col {
            &-contact {
                .content {
                    a {
                        text-decoration: underline;
                    }
                }
            }

            &-newsletter {
                form {
                    label .label {
                        display: none;
                    }
                    input {
                        height: rem-calc(50);
                        border-radius: 7px;
                        border: none;
                    }
                    button {
                        @include button-lybe($expand: true);
                        border-color: $white;
                    }
                }
            }
        }

        .accordion {
            .title {
                &:after {
                    @include scout-icon($icon: $icon-chevron-down, $fontSize: 12px);
                    color: $white;
                    display: block;
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transition: transform .3s;
                    transform: translateY(-50%);
                }
                &:hover {
                    text-decoration: none;
                }
            }
            .content {
                background: get-color(primaryDark);
                display: none;
                padding-top: 10px;
            }

            .block {
                &.is-active {
                    .title {
                        &:after {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }

        @include breakpoint(medium) {
            &.content {
                @include xy-grid;
                @include xy-gutters($negative: true);
            }

            .footer-col-store {
                @include xy-cell(6);
                order: 1;
            }

            .footer-col-contact {
                @include xy-cell(6);
                order: 3;
            }

            .footer-col-information {
                @include xy-cell(6);
                order: 4;
            }

            .footer-col-newsletter {
                @include xy-cell(6);
                order: 2;
            }

            .block {
                border-bottom: none;

                .title {
                    &:after {
                        display: none;
                    }
                }

                .content {
                    background: none;
                    display: block;
                    margin-bottom: 1rem;
                    padding-bottom: 0;
                    padding-top: 0;

                    ul {
                        li {
                            line-height: inherit;
                        }
                    }
                }
            }
        }

        @include breakpoint(large) {
            .footer-col-store {
                @include xy-cell(4);
                order: 1;
            }

            .footer-col-contact {
                @include xy-cell(2);
                order: 2;
            }

            .footer-col-information {
                @include xy-cell(3);
                padding-left: 40px;
                order: 3;
            }

            .footer-col-newsletter {
                @include xy-cell(3);
                order: 4;
            }
        }
    }

    a,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #fff;
    }

    a {
        &:hover {
            text-decoration: underline;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    small {
        font-size: 0.75rem;
        font-weight: bold;
    }

    .accordion-menu {
        width: 100%;
        margin-bottom: 1rem;
        border-top: 1px solid #363A43;

        a,
        .is-accordion-submenu a {
            color: #fff;
            padding: 1rem 0.5rem;
            font-size: 1rem;
            border-bottom: 1px solid #363A43;
        }

        .is-accordion-submenu a {
            font-size: 0.875rem;
        }

        ul.nested.is-accordion-submenu {
            background: #191C21;
        }
    }

    .footer-bottom {
        @include xy-grid;
        @include xy-gutters($negative: true);

        padding-top: 2rem;

        .footer-divider-wrapper {
            @include xy-cell(full, false, 0);

            hr {
                border-bottom-color: #404551;
            }
        }

        small.copyright,
        .footer-trust-marks {
            @include xy-cell($breakpoint: small);
            text-align: center;
        }

        small.copyright {}

        .footer-trust-marks {
            img {
                width: 100%;
                max-width: 484px; // WAT? create/use existing columns helper function
            }
        }

    }

    .about {
        margin-top: 2rem;
        background: no-repeat center center #ffffff;

        .grid-x {
            padding: 20px 0 40px;
        }

        h2,
        p {
            color: get-color(stone);
        }

        hr {
            border-color: $light-gray;
        }
    }
}
