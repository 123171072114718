.main {
    header {
        padding: 0 0 1rem;
        font-size: 2rem;
        font-weight: 500;
        text-align: center;
    }
}

.form-address-edit {
    label:not([for="primary_billing"]):not([for="primary_shipping"]) {
        @include form-label();
    }
    
    @include breakpoint(medium) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }

    .actions-toolbar {
        display: grid;
        gap: 1rem;
        padding: 0;

        grid-column: span 2;

        @include breakpoint(medium) {
            grid-template-columns: repeat(2, 1fr);
        }

        .secondary {
            @include breakpoint(medium) {
                order: -1;
            }
        }

        .action {
            @include button-lybe($expand: true);

            &.back {
                @include button-lybe($style: hollow);

                @include breakpoint(medium) {
                    width: auto;
                }
            }
        }
    }
}
