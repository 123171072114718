.fieldset.coupon {
        @include xy-grid();

        input {
            border-radius: 7px;
            margin: 0;
        }

        .field,
        .actions-toolbar {
            @include xy-cell(12, $gutters: 0);
        }

        .actions-toolbar {
            button {
                @include button-lybe($expand: true);
            }
        }

        @include breakpoint(medium) {
            .field {
                @include xy-cell(auto, $gutters: 0);
            }

            .actions-toolbar {
                @include xy-cell(shrink, $gutters: 0);
            }
        }
}
