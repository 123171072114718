.qty {
    .control {
        align-content: center;
        display: flex;
    }

    .qty-minus,
    .qty-plus {
        border-radius: 7px;
        border-color: $light-gray;
        border-style: solid;
        display: block;
        font-size: rem-calc(24);
        height: rem-calc(50px);
        padding: rem-calc(6) 0;
        width: rem-calc(40px);
        border-width: 2px;
        position: relative;
        background-color: get-color(white);
        text-align: center;
        user-select: none;

        &:hover {
            background: $light-gray;
            cursor: pointer;
            z-index: 2;
        }
    }

    input.input-text.qty {
        border-width: 2px 0;
        margin: 0;
        text-align: center;
        z-index: 1;
        min-width: 3rem;
        flex: 0 1;


        &:focus {
            border-color: $light-gray;
        }
    }
}
