@mixin form-label() {
    padding: 0.5rem 1.5rem;
    text-transform: uppercase;
    font-size: smaller;
    position: relative;
    font-weight: 500;
    margin-bottom: -2px;
    border-radius: 4px 4px 0 0;
    border: $input-border;
    background: $almost-white;
    color: get-color(primaryDark);
}
