.back-to-shop {
    @include button-lybe($size: small);

    .text {
        vertical-align: text-bottom;
    }

    @include breakpoint(large) {
        @include button-lybe();

        .text {
            line-height: 1rem;
        }
    }
}
