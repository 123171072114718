* {
    outline: none;
}
body {
    background-color: $body-background;
    overflow-x: hidden;
}
/* Inline */

.page-wrapper {
    @include breakpoint(medium) {
        margin-bottom: 0;
    }
}

.content-overlay {
    .page-wrapper {
        &:after {
            background: $content-overlay-background;
            bottom: 0;
            content: " ";
            display: block;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
        }
    }
}

main {
    @include xy-grid-container;
    background: $white;
    padding-top: 10px;
    padding-bottom: 60px;

    @include breakpoint(large) {
        padding-top: 28px;
    }
}

.breadcrumbs + main {
    padding-top: 0;
}

p.lead {
    font-size: 1.25rem;
    margin-bottom: 2.5rem;
}
p.small {
    font-size: .813rem;
}
blockquote {
    font-size: 1.5rem;
    padding: 0;
    font-style: italic;
}
.caption {
    color: #8A8D96;
    font-size: .875rem;
    display: block;
    margin-top: 10px;
    &.aside {
        margin-top: 0;
        padding-top: 10px;
        border-top: 1px solid $light-gray;
    }
}
.label {
    font-weight: bold;
    text-transform: uppercase;
}

.blog-sell {
    font-weight: $global-weight-bold;
    font-size: .875rem;
    margin-bottom: 1.5rem;
    img {
        display: block;
        margin: 0 auto;
    }
}
.blog-meta {
    margin-top: 2.5rem;
    padding-top: 1rem;
    border-top: 1px solid $light-gray;
    span {
        display: block;
        color: $dark-gray;
        font-size: .875rem;
    }
}
h5 {
    text-transform: uppercase;
}
/* Form */

fieldset, .fieldset {
    padding: 0;
    margin: 0;
    border: 0;
    legend {
        display: none;
    }
}

label.label, span.label, legend.label {
    background: transparent;
    padding: 0;
    color: $black;
    font-size: inherit;
    text-transform: none;
    font-weight: normal;
    margin-bottom: 10px;
}

textarea {
    min-height: 100px;
}
.accordion {
    border-top: 1px solid #CFD3DC;
    .accordion-item {
        border-bottom: 1px solid #CFD3DC;
        .accordion-title {
            font-size: .875rem;
            text-transform: uppercase;
            font-weight: $global-weight-bold;
            &:hover, &:focus {
                color: $black;
            }
            &:before {
                right: 0;
            }
        }
    }
}

/* Elements */

h4 + hr {
    margin-top: 0;
}

.progress-meter,
.slider-fill,
.slider {
    border-radius: 100px;
}
.slider-handle {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    &:after {
        content: '';
        display: block;
        width: 1px;
        height: 9px;
        position: absolute;
        top: 6px;
        left: 10px;
        background: #cfd3dc;
        box-shadow: 3px 0 0 #cfd3dc, -3px 0 0 #cfd3dc;
    }
}



/* Misc */

.tooltip {
    font-weight: $global-weight-bold;
    text-shadow: 0 1px 2px rgba(0,0,0,0.5);
    box-shadow: 0 2px 3px rgba(0,0,0,0.3);
    text-align: center;
}

.card {
    button, .button {
        margin-bottom: 0;
    }
    .card-section {
        background: $white;
    }
}

.tabs {
    box-shadow: inset 0 -1px 0 $light-gray;
    .tabs-title.is-active {
        box-shadow: inset 0 -4px 0 #363A43;
    }
}
table {
    font-size: .813rem;
    &.striped tbody tr:nth-child(even) {
        border-bottom: 1px solid $light-gray;
    }
    thead {
        text-transform: uppercase;
        font-size: .75rem;
    }
}

.field-tooltip-content, span.note {
    font-size: .813rem;
    font-style: italic;
}

.reveal {
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.22), 0 24px 24px rgba(0, 0, 0, 0.3);
}

/* Magento */
::selection {
    color: #fff;
    background: #F57500;
    /* WebKit/Blink Browsers */
}
::-moz-selection {
    color: #fff;
    background: #F57500;
    /* Gecko Browsers */
}

.no-margin { margin: 0; }

.dummy-ul {
    li { list-style: none; }
}

.skip {
  @include hide;
}

.breadcrumbs {
  @include breadcrumbs;
}

.pages-items {
    @include pagination;
}

.visible {
  visibility: visible !important;
}

// =========
// = Hacks =
// =========
// COMBAK: TBR. Refactor all below

// TODO disable these in Magento (XML or module: false)
[href*="requisition_list"],
#invitation-send-link {
    display: none !important;
}
