// COMBAK: Björn: discuss file usage and nessisity w anton and farouk

.bg-orange {
  background: $color-orange;
}

.bg-green {
  background: $color-green;
}

.bg-red {
  background: get-color(alert);
}

.bg-lightgray,
.bg-lightgrey {
  background: $light-gray;
}

.color-red {
  color: get-color(alert);
}

.font-bold {
  font-weight: bold;
}
