// TODO: Please separate this file into several components. Maximum around 100 lines per file, please? :)

.page-header {
    background-color: $primary-color;
    color: $white;
    position: relative;
    z-index: 2;

    header .logo {
        @extend .text-center,
        .medium-text-left;
    }

    .desktop-logo {
        @include show-for(medium);
    }

    .mobile-logo {
        @include hide-for(medium);
        max-height: 20px;
    }

    a {
        color: $white;
    }

    .top-bar {
        height: 33px;
        font-size: 12px;
        font-weight: bold;
        padding: 0;

        img[src$=".svg"] {
            width: 16px;
            position: relative;
            margin-right: 5px;
        }

        .switch {
            margin-bottom: 0;

            span {
                font-size: 12px;
            }

            .switch-active {
                z-index: 1;
                left: 15%;
            }

            .switch-inactive {
                z-index: 1;
                right: 13%;
            }

            .switch-paddle {
                background: #494D56;
                width: 150px;

                &:after {
                    background: #2b2e37;
                    width: 75px;
                }
            }

            input:checked~.switch-paddle {
                background: #494D56 !important;

                &:after {
                    left: 72px;
                }
            }

            input:checked+label>.switch-inactive,
            .switch-active {
                display: block;
            }
        }
    }

    .panel.wrapper {
        border-bottom: 1px solid #002644;
        display: block;
        padding-bottom: 7px;
        padding-top: 7px;
    }

    .header {
        .header-top-message-wrapper {
            color: $white;
            grid-column: 2/3;
            display: flex;

            .header-top-message {
                margin: 0.5rem;
                display: flex;
                align-items: center;
                font-size: 0.875rem;
                font-weight: 500;
                line-height: 1.25rem;

                &.hidden {
                    display: none;
                }

                &.fadeIn {
                    animation: fadeIn 0.5s ease-in-out;
                }

                @keyframes fadeIn {
                    from {
                        opacity: 0;
                    }
                    to {
                        opacity: 1;
                    }
                }
                @include breakpoint(large) {
                    &.hidden {
                        display: flex;
                    }

                    &.fadeIn {
                        animation: unset;
                    }
                }

                @include breakpoint(large) {
                    &:last-of-type {
                        &:after {
                            display: none;
                        }
                    }

                    &:after {
                        content: '';
                        display: inline-block;
                        height: 1rem;
                        width: 1px;
                        background-color: $white;
                        margin: 0 0 0 1rem;
                    }
                }
            }
        }

        .my-account,
        .my-wishlist {
            &:after {
                float: left;
                margin-right: 1rem;
                width: 20px;
                text-align: center;
                @include breakpoint(medium) {
                    margin-right: auto;
                    margin-left: 0.5rem;
                    float: right;
                }
            }
        }
        .my-account {
            &:after {
                @include scout-icon($icon: $icon-user, $fontSize: rem-calc(18));
                color: $color-sky;
            }
        }

        .my-wishlist {
            &:after {
                @include scout-icon($icon: $icon-heart, $fontSize: rem-calc(20));
                color: get_color(pink);
            }
        }

        &.links {
            display: none;
            list-style: none;
            margin-bottom: 0;

        }

        &.panel {
            display: grid;
            grid-template-columns: 1fr auto 1fr;
            align-items: center;
        }

        @include breakpoint(medium) {
            &-top-menu {
                @include xy-cell(8);
                list-style: none;
                margin-bottom: 0;

                li {
                    display: inline-block;
                    padding-right: 15px;
                }

                a {
                    color: #adbbc7;
                    font-size: 14px;
                    font-weight: $global-weight-bold;

                    &:hover {
                        color: $white;
                    }
                }
            }

            &.links {
                display: unset;
                grid-column: 3/4;
                text-align: right;

                li {
                    display: inline-block;
                    padding-right: rem-calc(16);

                    &:last-child {
                        padding-right: 0;
                    }
                }

                a {
                    cursor: pointer;
                    display: block;
                    font-size: rem-calc(14);
                    font-weight: $global-weight-bold;
                    line-height: rem-calc(20);
                }
            }
        }

        &.content {
            @include xy-grid;
            align-items: center;
            flex-flow: row;
            justify-content: space-between;
            position: relative;

            .nav-toggle {
                @include hide-for(medium);
                margin-left: 0;
                text-align: center;
                width: 45px;

                img {
                    height: 1.5rem;
                }

                span {
                    display: block;
                }
            }

            .logo {
                flex-grow: 1;
                padding: 10px 0;
                text-align: left;

                .logo-text {
                    display: inline-block;
                    font-size: 28px;
                    line-height: 1;
                    padding: 12px 0 0 5px;

                    strong {
                        font-family: $body-font-family-condensed;
                        font-weight: $global-weight-bold;
                    }
                }

                img {
                    float: left;
                    width: 360px;
                }
            }

            @include breakpoint(small only) {
                .authorization-link {
                    display: none;
                }
            }

            .account {
                // TODO: set as flex not float!
                position: relative;
                font-weight: $global-weight-bold;
                font-size: .875rem;

                .authorization-link {
                    text-decoration: underline;

                    img[src$=".svg"] {
                        position: relative;
                        margin-left: 1rem;
                    }
                }
            }

            // Top right header wrapper
            .block-header-right {
                @include show-for(medium);

                @include breakpoint(medium) {
                    @include xy-cell(4);
                }
            }

            .block-header-right-text {
                float: right;
            }

            // Saldo top right in header
            #budget-header {
                text-align: right;
                margin-right: 1em;
            }

            #budget-header span {
                color: #CFD3DC;
                font-family: "Open Sans";
                font-size: 12px;
                font-weight: bold;
                display: inline-block;
            }

            span#budget-header-balance {
                color: white;
            }

            // Fullname shown top right in header
            #customer-fullname-wrapper {
                color: #fff;
                line-height: inherit;
                text-decoration: underline;
                cursor: pointer;
                position: relative;
                font-weight: bold;
                font-size: .875rem;
                text-align: right;
                margin-right: 1em;
            }

            #customer-fullname-wrapper a span {
                font-size: .875rem;
            }

            #customer-fullname-wrapper:hover {
                color: #fff;
            }

            @include breakpoint(medium) {
                @include xy-gutters($negative: true);
                align-items: center;

                .logo {
                    @include xy-cell(5);
                    order: 1;
                    padding: 20px 0;

                    .logo-text {
                        font-size: rem-calc(30);
                        padding-top: 10px;
                    }
                }
            }

            @include breakpoint(large) {
                @include xy-gutters($negative: true);
                align-items: center;

                .logo {
                    @include xy-cell(4);

                    .logo-text {
                        font-size: rem-calc(48);
                        padding-top: 10px;
                    }
                }
            }
        }
    }
}

.sticky-header {
    position: fixed;
    width: 100%;
    @include breakpoint(medium) {
        position: relative;
    }
}

.usp {
    @include show-for(large);

    background: #fff;
    font-size: 13px;
    color: #526580;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    text-align: center;

    .grid-x {
        height: 40px;
    }

    span {
        position: relative;
        top: -4px;
    }

    img,
    svg {
        margin-right: 5px;
        position: relative;
        top: 2px;
    }

    svg {

        circle,
        ellipse,
        line,
        path,
        polyline {
            stroke: #526580;
        }
    }

    img {
        vertical-align: baseline;
    }
}
