.checkout-cart-index {
    .back-to-shop-wrapper {
        margin-bottom: 0.5rem;

        @include breakpoint(large) {
            @include xy-grid-container();

            margin-bottom: 1rem;
        }

        @import 'back-to-shop';
    }

    .page-title-wrapper {
        @import 'page-title';
    }

    .cart-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;

        @include breakpoint(medium) {
            margin-bottom: 0;
        }

        @include breakpoint(large) {
            @include xy-grid();
            .form-cart {
                @include xy-cell(7);
            }
            .cart-summary {
                @include xy-cell(5);
            }
        }

        .form-cart {
            @import 'cart-product';
            @import 'cart-product-item-details';

            order: 1;
        }

        .cart-summary {
            @import 'cart-summary';
            @import 'cart-totals-table';
            @import 'cart-discount-coupon';

            order: 2;

            @include breakpoint(medium only) {
                @include xy-grid();
                @include xy-gutters($negative: true);
            }

            // Pull the Summary block so that it vertically aligns with the Page title
            @include breakpoint(large) {
                transform: translateY(rem-calc(-54px));
            }
        }
    }

    .cart-empty {
        @import 'cart-empty';
    }

    .crosssell {
        @import 'cart-crosssell';
    }
}
