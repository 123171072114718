@mixin scout-icon(
    $icon,
    $fontSize: 24px,
    $lineHeight: 1
) {
    font-family: 'scout';
    font-weight: normal;
    font-style: normal;
    font-size: $fontSize;
    display: inline-block;
    @if $lineHeight {
        line-height: $lineHeight;
    }
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    content: $icon;
}
