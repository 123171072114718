.toolbar {
    display: block;
    border-top: none;

    @include breakpoint(medium) {
        padding: 0.5rem;
    }

    .pager {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-gap: 0.5rem;
        align-items: center;

        @include breakpoint(medium) {
            grid-template-columns: auto 1fr auto;
        }

        .pages {
            grid-column: 1/3;

            border-top: 1px solid $light-gray;
            margin-top: 0.75rem;
            padding-top: 1rem;

            @include breakpoint(medium) {
                grid-column: auto;

                border-top: none;

                border-left: 1px solid $light-gray;
                border-right: 1px solid $light-gray;
                margin: 0 5%;
                padding: 0 0.5rem;
            }

            @include breakpoint(large) {
                margin: 0 10%;
                padding: 0 1rem;
            }
        }

        .pages + .limiter {
            grid-column: 2/3;

            @include breakpoint(medium) {
                grid-column: auto;
            }
        }

        .limiter {
            grid-row: 1;
            grid-column: 3/4;

            @include breakpoint(medium) {
                grid-row: auto;
            }

            display: grid;
            gap: 1rem;
            grid-template-columns: repeat(3, auto);
            align-items: center;
            text-align: center;

            .limiter-options {
                margin-bottom: 0;
            }
        }
    }
}
