.catalog-product-view {
    .breadcrumbs {
        display: none;
    }

    @include breakpoint(medium up) {
        .breadcrumbs {
            display: block;
        }
    }
}

.product {
    &.media {
        margin-bottom: rem-calc(30);
        width: 100%;
    }

    &-info-main {
        @include xy-cell($breakpoint: small);
        margin-bottom: rem-calc(30);
    }

    @include breakpoint(medium up) {
        &.media {
            @include xy-cell(6);
        }

        &-info-main {
            @include xy-cell(6);
        }
    }

    @include breakpoint(large) {
        &.media {
            @include xy-cell(5);
        }

        &-info-main {
            @include xy-cell(6);
            @include xy-cell-offset(1);
        }
    }
}

.print-product {
    @include xy-cell;
    text-align: center;

    @include breakpoint(large) {
        @include xy-cell(3);
    }
}

// main elements

.product {
    &.media {
        .fotorama {
            &__arr {
                background: none;
                width: 40px;

                &__arr {
                    background: $white;
                    border: 1px solid $light-gray;
                    border-radius: 50%;
                    box-sizing: border-box;
                    height: 20px;
                    line-height: rem-calc(18);
                    text-align: center;
                    transition: .3s ease;
                    width: 20px;

                    &:hover {
                        background: $primary-color;
                        color: $white;
                    }
                }

                &--prev {
                    .fotorama__arr__arr {
                        &:before {
                            @include scout-icon($icon-chevron-left, $fontSize: rem-calc(8), $lineHeight: false);
                        }
                    }
                }

                &--next {
                    .fotorama__arr__arr {
                        &:before {
                            @include scout-icon($icon-chevron-right, $fontSize: rem-calc(8), $lineHeight: false);
                        }
                    }
                }

                &--disabled {
                    opacity: 1;
                }
            }

            &__nav {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                top: auto;

                &--dots {
                    .fotorama__nav__frame {
                        width: 1rem;
                    }
                }
            }

            &__dot {
                background: $white;
                border-color: $light-gray;
                height: 6px;
                left: 4px;
                width: 6px;
            }

            &__active {
                .fotorama__dot {
                    background: $primary-color;
                    border-color: $primary-color;
                }
            }
        }
    }

    &.page-title-wrapper {
        h1 {
            color: get-color(cta);
        }
    }

    &-view-row {
        @include xy-grid;
        @include xy-gutters($negative: true);

        margin-bottom: 1.125rem;

        &.product-view-row-main {
            margin-bottom: 0;
        }
    }

    &-info-main {
        padding-top: 1rem;

        .sizeguide-trigger {
            cursor: pointer;
            display: inline-block;
            margin-bottom: rem-calc(10);

            .icon-ruler {
                font-size: rem-calc(14);
                margin-right: .5rem;
            }

            span {
                color: $color-shark;
                text-decoration: underline;
            }
        }

        .grouped-product {
            .associated-products {
                list-style: none;
                margin: 0;
                padding: 0;

                .item {
                    @include price();
                }

                .product-item-details {
                    align-content: center;
                    align-items: center;
                    display: flex;
                }

                .product-item-name {
                    margin-right: 1rem;
                }
            }
        }
    }

    &.attribute {
        &.sku {
            line-height: 18px;

            .type,
            .value {
                font-size: .813rem;
                color: $dark-gray;
            }
        }
        &.overview {
            margin-top: 2rem;
        }
    }

    &-info-stock {
        visibility: hidden;

        &.visible {
            visibility: visible;
        }

        margin-bottom: 2rem;
        font-size: 0.875rem;

        a {
            color: $black;
            text-decoration: underline;

            &:hover {
                color: $primary-color;
            }
        }
    }

    &-options-wrapper {
        @include xy-grid;
        @include xy-gutters($negative: true);

        .fieldset {
            @include xy-cell(full, $breakpoint: small);

            @include breakpoint(medium) {
                @include xy-cell(full);
            }

            label {
                @include hide;
            }

            select {
                border-radius: 7px;
                margin-bottom: 0;
            }
        }
    }

    &-info-price {
        margin-bottom: 1.5rem;

        .price {
            &-label {
                @include hide;
            }
        }

        .price-final_price .price {
            color: $color-orange;
            font-size: rem-calc(24);
            font-weight: $global-weight-bold;
        }

        .special-price {
            .price-final_price .price {
                color: get-color(red);
            }
        }

        .old-price {
            .price-final_price .price {
                color: $medium-gray;
                font-size: rem-calc(20);
                font-weight: $global-weight-normal;
                margin-right: 10px;
                text-decoration: line-through;
            }

            .price-tax-label {
                display: none;
            }
        }
    }

    &-add-form {
        .box-tocart .fieldset {
            @include xy-grid;
            @include xy-gutters($negative: true);
            border: 0;
            padding: 1.25rem 0 0;
            margin-bottom: 0;
        }

        .tocart {
          @include button-lybe(true, get-color(cta))
        }

        .qty {
            &.field {
                @include xy-cell(shrink, $breakpoint: small);
                order: 1;
                margin-right: 0;

                @include breakpoint(large up) {
                    @include xy-cell(shrink);
                    margin-right: 0;
                }
            }

            .control {
                align-content: center;
                display: flex;
            }

            .qty-minus,
            .qty-plus {
                border-radius: 7px;
                border-color: $light-gray;
                border-style: solid;
                display: block;
                font-size: rem-calc(24);
                height: 50px;
                padding: rem-calc(6) 0;
                width: 40px;
            }

            .qty-minus,
            .qty-plus {
                border-width: 2px;
                position: relative;
                background: $table-background;
                text-align: center;
                user-select: none;

                img[src$=".svg"] {
                    margin-top: 6px;
                }

                &:hover {
                    background: $light-gray;
                    cursor: pointer;
                    z-index: 2;
                }
            }

            input#qty {
                border-width: 2px 0;
                margin: 0;
                text-align: center;
                width: 65px;
                z-index: 1;

                &[type=number]::-webkit-inner-spin-button,
                &[type=number]::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                &:focus {
                    border-color: $light-gray;
                }
            }
        }

        .actions {
            @include xy-cell(full, $breakpoint: small);
            order: 3;

            @include breakpoint(medium) {
                @include xy-cell(5);
                order: 2;
            }
        }

        .action {
            &.towishlist {
                @include xy-cell(shrink);
                border: 2px solid $light-gray;
                box-sizing: border-box;
                display: block;
                line-height: 1;
                margin-bottom: 1rem;
                margin-left: 0;
                order: 2;
                padding: rem-calc(10) rem-calc(12);
                text-align: center;

                span {
                    display: none;
                }

                &:before {
                    @include scout-icon($icon-heart, $fontSize: rem-calc(24));
                }

                @include breakpoint(medium) {
                    order: 3;
                }
            }
        }
    }

    &-info-availability {
        border-bottom: 1px solid $light-gray;
        border-top: 1px solid $light-gray;
        margin-bottom: 1rem;
        margin-top: 1rem;
        padding: 1rem 0;

        .availability-inner {
            @include xy-grid;
            @include xy-gutters($negative: true);

            .stock {
                @include xy-cell(6);
                align-content: center;
                align-items: center;
                display: flex;

                &.available:before {
                    @include scout-icon($icon-check, $fontSize: rem-calc(16));
                    color: $color-green;
                    margin-right: .5rem;
                }
            }
        }
    }

    &.info.detailed {
        .data.item {
            &.title {
                display: none;
            }
        }
    }

    &-usp-wrapper {
        border-bottom: 1px solid $light-gray;
        border-top: 1px solid $light-gray;
        font-size: rem-calc(14);
        margin-bottom: rem-calc(30);
        margin-top: rem-calc(30);
        padding: 1rem 0;

        .container {
            display: flex;
            flex-flow: column;
        }

        .container > * {
            padding-left: 1.6rem;
            padding-right: 1.6rem;
        }

        .shipping {
            align-content: center;
            align-items: center;
            display: flex;
            margin-bottom: 1rem;

            span {
                flex-basis: 0;
                flex-grow: 1;
            }

            &:before {
                @include scout-icon($icon-truck, $fontSize: rem-calc(24));
                color: $color-purple;
                flex-basis: rem-calc(30);
                margin-right: 1rem;
                text-align: center;
            }
        }
        .eco-friendly {
            align-content: center;
            align-items: center;
            display: flex;
            margin-bottom: 1rem;

            span {
                flex-basis: 0;
                flex-grow: 1;
            }

            &:before {
                @include scout-icon($icon-leaf, $fontSize: rem-calc(24));
                color: $color-green;
                flex-basis: rem-calc(30);
                margin-right: 1rem;
                text-align: center;
            }
        }
        .support {
            align-content: center;
            align-items: center;
            display: flex;

            span {
                flex-basis: 0;
                flex-grow: 1;
            }

            &:before {
                @include scout-icon($icon-chat, $fontSize: rem-calc(24));
                color: $color-blue;
                flex-basis: rem-calc(30);
                margin-right: 1rem;
                text-align: center;
            }
        }
    }

    @include breakpoint(medium up) {
        &.media {
            .fotorama {
                &__nav {
                    overflow: hidden;
                    padding: 20px 30px 0;
                    position: relative;

                    &-wrap {
                        &--horizontal {
                            .fotorama__thumb__arr {
                                background: $white;
                                top: 20px;

                                &--left,
                                &--right {
                                    .fotorama__thumb--icon {
                                        width: 20px;
                                    }
                                }
                            }
                        }
                    }

                    &.fotorama__shadows {
                        &--left {
                            &:before {
                                content: '';
                                display: none;
                            }
                        }
                        &--right {
                            &:after {
                                content: '';
                                display: none;
                            }
                        }
                    }

                    &--thumbs {
                        .fotorama__nav__frame {
                            .fotorama__thumb {
                                width: calc(100% - 8px);
                                height: calc(100% - 8px);
                                margin: 4px;
                            }
                        }
                    }
                }

                &__thumb {
                    &--icon {
                        background-color: $white;
                        background-image: none;
                        border: 1px solid $light-gray;
                        border-radius: 50%;
                        box-sizing: border-box;
                        height: 20px;
                        line-height: rem-calc(18);
                        padding: 0;
                        text-align: center;
                        transition: .3s ease;
                        width: 20px;

                        &:hover {
                            background: $primary-color;
                            color: $white;
                        }
                    }

                    &__arr {
                        &--left {
                            .fotorama__thumb--icon {
                                &:before {
                                    @include scout-icon($icon-chevron-left, $fontSize: rem-calc(8), $lineHeight: false);
                                }
                            }
                        }

                        &--right {
                            .fotorama__thumb--icon {
                                &:before {
                                    @include scout-icon($icon-chevron-right, $fontSize: rem-calc(8), $lineHeight: false);
                                }
                            }
                        }
                    }

                    &-border {
                        border-color: $primary-color;
                        border-width: 1px;
                    }
                }
            }
        }

        &-info-main {
            padding-top: 0;
        }

        &-info-availability {
            .availability-inner {
                .stock {
                    @include xy-cell(4);
                }
            }
        }

        &-usp-wrapper {
            .container {
                margin: 0 auto;
                flex-flow: row;

                .shipping,
                .eco-friendly,
                .support {
                    flex-basis: 0;
                    flex-grow: 1;
                    margin: 0;
                    justify-content: center;
                }
            }
        }
    }

    @include breakpoint(large up) {
        &-usp-wrapper {
            .container {
                max-width: 75%;
            }
        }
    }
}

.print-product a {
    text-transform: uppercase;
    font-weight: bold;
    font-size: .75rem;
    color: #526580;

    img[src$=".svg"] {
        margin-right: 5px;
    }
}

.product-info-usp {
    font-size: .813rem;

    .callout {
        padding: 1rem 1rem 0;

        svg {
            height: 36px;
            width: 36px;
            margin-right: 1rem;
        }
    }
}

.product-view-extend {
    @include callout();
    margin-left: 0;
    width: 100%;
    border: 0;
    background: $almost-white;
    margin-bottom: 1.125rem;

    // TBD? what is this?
    @media (min-width: 1240px) {
        max-width: none;
        width: 1000vw;
        margin-left: -500%;
        position: relative;
        left: 250%;
    }


    .description {
        .type {
            @extend h4;
        }
    }

    table {

        th,
        td {
            font-size: 0.875rem;
            font-weight: normal;
            border-right: 2px solid $almost-white;
        }
    }

    .support {
        font-size: 0.875rem;

        span {
            display: inline-block;
            margin-right: 1rem;
            margin-left: 0;

            @media (min-width: 1024px) {
                margin-right: 0;
                margin-left: 1rem;
            }

            img[src$=".svg"] {
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }

            a {
                color: $black;
            }
        }
    }
}

.related,
.upsell {
    .title {
        color: $primary-color;
        font-family: $body-font-family-condensed;
        font-size: rem-calc(24);
        margin-bottom: 1rem;

        @include breakpoint(medium) {
            font-size: rem-calc(32);
        }
    }

    .product-item {
        @include price();
    }

    @include breakpoint(small down) {
        .products.list {
            @include xy-grid;
            @include xy-gutters($negative: true);
            width: initial;

            .product-item {
                @include xy-cell(6, $breakpoint: small);
                margin-bottom: rem-calc(10);
            }
        }
    }
}
