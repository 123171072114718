.product-item {
    padding: 0.5rem 0;
    border-bottom: 1px solid $light-gray;

    &:last-child {
        border-bottom: none;
    }
}

.product {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0.75rem;
}

.product-image-container {
    img {
        border: 1px solid $light-gray;
    }
}

.product-item-inner {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0.25rem;

    .product-item-name {
        grid-column: 1 / 3;

        font-weight: normal;
        @include text-truncate();
    }

    .details-qty {
        display: inline-flex;
        input {
            margin: 0;
        }
    }

    .remove-item {
        justify-self: end;
        align-self: center;

        cursor: pointer;
        padding: 0.25rem;

        &:hover {
            color: get-color(alert);
        }
    }

    .subtotal {
        display: inline-block;
        margin-top: rem-calc(3px);
        .price {
            color: $color-orange;
        }
    }
}
