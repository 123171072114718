.catalogsearch-result-index,
.catalog-category-view {
    &.page-layout-1column {
        .page-title-wrapper {
            display: none;
        }
    }

    &.page-layout-2columns-left {
        .column.main {
            @include breakpoint(medium) {
                @include xy-cell(9);
            }
        }
    }

    .sidebar {
        &.sidebar-main,
        &.sidebar-additional {
            order: 1;
            display: none;

            @include breakpoint(medium) {
                @include xy-cell(3);
                display: block;
            }
        }
    }

    .page-title-wrapper {
        .page-title {
            line-height: 1;
        }
    }

    .column.main {
        order: 2;

        .category-view {
            .category-image {
                display: none;
            }

            .category-description-wrapper {
                &.truncate {
                    @include text-truncate-multiline($lines: 6)
                }
            }

            .category-description-btn {
                display: none;
                width: 100%;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    display: block;
                    top: -60px;
                    width: 100%;
                    z-index: 1;
                    height: 60px;
                    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 1) 100%);
                    /* FF3.6-15 */
                    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 1) 100%);
                    /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 1) 100%);
                    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
                    /* IE6-9 */
                }

                .line-wrapper {
                    display: flex;
                    align-content: center;
                    align-items: center;
                    position: relative;
                    text-align: center;
                    width: 100%;

                    .line {
                        background: $border-color;
                        display: block;
                        flex-grow: 1;
                        height: 1px;
                    }

                    .text {
                        margin: 0 1rem;
                        color: get-color(cta);
                        font-size: rem-calc(14);
                        font-weight: $global-weight-bold;
                    }
                }

                &.active {
                    display: block;
                }
            }

            margin-bottom: 1.25rem;
        }

        .products.wrapper {
            @include xy-gutters($negative: true);
            margin-top: $global-margin;
        }

        @include breakpoint(medium up) {
            .category-view {
                .category-image {
                    display: block;
                    img {
                        max-height: 15rem;
                    }
                }
            }
        }
    }

    .toolbar {

        // all toolbar(s)
        .pages,
        .toolbar-amount {
            display: none;
        }
    }

    // toolbar-bottom
    .products.wrapper + .toolbar.toolbar-products {

        .toolbar-amount,
        .pages {
            display: block;
            width: 100%;
        }

        .pages {
            order: 1;
        }

        .toolbar-amount {
            order: 2;
        }

        .filter-button,
        .modes,
        .toolbar-sorter {
            display: none;
        }
    }

    .toolbar-amount {
        text-align: center;
        color: $medium-gray;
        font-size: .75rem;
        text-transform: initial;

        .toolbar-number {
            font-weight: bold;
        }
    }

    // FILTERS
    .filter-wrapper {
        width: 100%;
        margin-bottom: 20px;

        .filter-content {
            margin-bottom: 0 !important;

            hr {
                margin-bottom: 5px;
            }
        }
    }

    .filter {
        @include filter-content();

        &.block {
            .title,
            .subtitle,
            .filter-title,
            .filter-subtitle {
                display: none;
            }
        }
    }

    .page-main {
        .filter,
        .navigation {
            @include sidebar-navigation();
        }
    }
}

.catalogsearch-result-index,
.catalog-category-view {
    .page-main .columns {
        @include flex-align($y: top);

        @include breakpoint(medium) {
            @include xy-grid;
            @include xy-gutters($negative: true);
        }
    }
}


// TODO
// Move away from this page. Suggestion: move into a separate "product-list" component load it
// with @import, follow this idea `pages/customer-account/_customer-table.scss` is loaded into its parent
.product-items {
    @include breakpoint(small only) {
        @include product-card('grid', $columns: 2, $actions: false);
    }

    @include breakpoint(medium up) {
        .products.grid.products-grid & {
            @include product-card('grid', 3);
        }

        .products.list.products-list & {
            @include product-card('list', $hr: true, $list-large: true);
        }
    }

    @include breakpoint(large up) {
        .products.grid.products-grid & {
            @include product-card('grid', 4);
        }
    }
}

.sidebar-main {
    @include flex-align($y: top);
}
