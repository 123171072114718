$initials-circle-page-header-size: 45px;
$initials-circle-dashboard-size: 90px;

.initials-circle {
  text-decoration: none;
  display: block;
  text-align: center;
  border-radius: 100%;
  font-weight: bold;
}

.initials-circle--page-header {
  @include show-for(medium);
  width: $initials-circle-page-header-size;
  height: $initials-circle-page-header-size;
  line-height: $initials-circle-page-header-size;
  font-size: $initials-circle-page-header-size / 3;
  float: right;
  position: relative;
}

.initials-circle--page-header:after {
  display: block;
  height: 19px;
  width: 19px;
  position: absolute;
  right: 0;
  bottom: -5px;
	border: 1px solid #161924;
  border-radius: 100%;
  font-size: 1.1em;
  color: #363A43;
  content: "\25BE";
  font-family: sans-serif;
  line-height: 15px;
  background: linear-gradient(180deg, #FFFFFF 0%, #929FB0 100%);
}

.initials-circle--dashboard {
  float: left;
  width: $initials-circle-dashboard-size;
  height: $initials-circle-dashboard-size;
  margin-right: 20px;
  line-height: $initials-circle-dashboard-size;
  font-size: 32px;
}

