.callout {
    &.secondary {
        background-color: #F3F4F6;
        border-color: #99A1B5;
        color: #51545C;
        .close-button, h1, h2, h3, h4, h5, h6 {
            color: #51545C;
        }
    }
    &.success {
        background-color: #E4F9D8;
        border-color: #6BB442;
        color: #377A11;
        .close-button, h1, h2, h3, h4, h5, h6 {
            color: #377A11;
        }
    }
    &.warning {
        background-color: #FFF1CC;
        border-color: #FFBA00;
        color: #8F5B00;
        .close-button, h1, h2, h3, h4, h5, h6 {
            color: #8F5B00;
        }
    }
    &.alert {
        background-color: #FFEAEA;
        border-color: #EF5959;
        color: #B11F1F;
        .close-button, h1, h2, h3, h4, h5, h6 {
            color: #B11F1F;
        }
    }
}
