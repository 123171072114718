// COMBAK: Björn: discuss file usage and nessisity w anton and farouk

// HELPER MIXINS

@mixin white-space($position: null) {
    @if $position == 'top' {
        margin-top: 0.5rem;
        @include breakpoint(medium) {
            margin-top: 20px;
        }
        @include breakpoint(large) {
            margin-top: 2.5rem
        }
    }
    @else {
        margin-bottom: 0.5rem;
        @include breakpoint(medium) {
            margin-bottom: 20px;
        }
        @include breakpoint(large) {
            margin-bottom: 2.5rem
        }
    }
}

// HELPER CLASSES
.white-space,
.space-top {
    @include white-space('top');
}
.space-bottom {
    @include white-space('bottom');
    img {
        width: 100%;
    }
}

@mixin checkbox($distance: 25px) {
  cursor: pointer;
  position: relative;
  padding-left: 25px !important;

  &:before,
  &.active::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    width: 18px;
    height: 18px;
    border-width: 2px;
    border-style: solid;
    border-radius: 2px;
  }

  &:before {
    border-color: $light-gray;
    background: #fff;
  }

  &:hover:before {
    border-color: $medium-gray;
  }

  &.active {
    &:before {
      border-color: #637797;
      background: #637797;
    }
    &:hover:before {
      border-color: #637797;
    }
  }
}

@mixin text-truncate-multiline($font-size: 1rem, $line-height: 1.5, $lines: 5, $max-height: 999px) {
  display: block;
  max-height: $font-size * $line-height * $lines + rem-calc(85px);
  margin: 0 auto;
  overflow: hidden;
  // TODO: Create proper transition handling
  // transition: max-height 0.75s ease-out;
  &.active {
    max-height: $max-height;
    padding-bottom: 1.2rem;
  }
}

@mixin hide {
  display: none !important;
}

@mixin arrow-down($color: $black, $distance: 0, $position: after) {
  display: inline-block;
  &:before,
  &:after {
    color: $color;
  }

  @if $position == after {
    &:after {
      content: "\25BE";
      margin-left: $distance;
    }
  } @else if $position == before {
    &:before {
      content: "\25BE";
      margin-right: $distance;
    }
  }
}

/**
* CSS based chevron (arrow)
* TODO Support all directions (transform, switch properties)
* TODO Shorten arguments list through $mixin-default-variables
* TODO Refactor this with @mixin `arrow-down`
***/
@mixin arrow-up($base: 5px, $height: 5px, $position: (x: 0, y: 0), $color: $black, $outline: false, $outline-color: get-color(white), $outline-offset: (x: -1px, y: -1px)) {

  &:before,
  &:after {
    width:0;
    position: absolute;
    border-right-style: solid;
    border-right-color: transparent;
    border-left-style: solid;
    border-left-color: transparent;
    border-bottom-style: solid;
  }

  // OUTLINE
  @if $outline == true {
    &:before {
      content: "";
      right: map-get($position, x) + map-get($outline-offset, x); // TODO add support for origin of offset left/right
      top: map-get($position, y) + map-get($outline-offset, y);


      border-right-width: $base * 1.2;
      border-left-width: $base * 1.2;

      border-bottom-width: $height * 1.2;
      border-bottom-color: $outline-color;
    }
  }

  // CONTENT
  &:after {
    content: "";
    right: map-get($position, x); // TODO add support for origin of offset left/right
    top: map-get($position, y);

    border-right-width: $base;
    border-left-width: $base;

    border-bottom-width: $height;
    border-bottom-color: $color;
  }
}
