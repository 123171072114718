.continue-shopping {
    display: initial;
    @include breakpoint(medium) {
        @include xy-gutters;
    }
}

.nav-toggle {
    display: none;
}

.header.content .logo {
    img {
        float: none;
    }
    .logo-text {
        vertical-align: bottom;
        padding-bottom: rem-calc(5px);
    }
}
