$navDiameter: 34px;

.product-top-list {
    .product-items {
        .owl-nav {
            position: absolute;
            top: 50%;
            transform: translateY(-100%);
            left: 0;
            right: 0;
            display: flex;
            justify-content: space-between;

            button[class*=owl-] {
                background: $white;
                border: 1px solid $light-gray;
                border-radius: 50%;
                box-sizing: border-box;
                height: $navDiameter;
                width: $navDiameter;
                padding: 0;
                line-height: 0;
                text-align: center;
                transition: .3s ease;


                &.owl-prev:before {
                    @include scout-icon($icon-chevron-left, $fontSize: rem-calc(8), $lineHeight: false);
                }

                &.owl-next:before {
                    @include scout-icon($icon-chevron-right, $fontSize: rem-calc(8), $lineHeight: false);
                }

                i {
                    display: none;
                }
            }
        }
    }
}
