.page.messages {
  $outOfScreen: rem-calc(-300);
  $visible: 0;

  bottom: $outOfScreen;
  width: 100%;
  position: fixed;
  left: 0;
  z-index: 1000;

  transition: bottom 400ms ease-in-out;

  &.has-messages {
    bottom: $visible;
  }

  > div[data-placeholder="messages"] {
    display: none;
  }

  > div {
    font-size: 20px;
    border-top: 2px solid get-color(white);
    background-color: get-color(cta);
    padding: rem-calc(10);
    color: get-color(white);
  }

  .message {
    div[data-bind="html: message.text"] {
      a {
        color: get-color(white);
        text-decoration: underline;
      }
    }
  }

}
