@mixin breadcrumbs() {
    @include xy-grid-container;
    background: $white;
    padding-bottom: 25px;
    padding-top: 25px;

    &:before {
        @include scout-icon($icon-tag, $fontSize: rem-calc(18));
        float: left;
        margin-right: .5rem;
    }

    > ul {
        @include breadcrumbs-container;
        margin-bottom: 0;
    }

    li {
        line-height: rem-calc(20);

        &:after {
            display: inline-block;
            float: right;
            line-height: 1;
        }

        strong {
            font-weight: $global-weight-normal;
        }
    }
}
