.header-account-dropdown {
  padding: 0;
  border-radius: 5px;
  width: 200px;
  list-style: none;
  background: get-color(white);
}

.header-account-dropdown__link {
  display: block;
  color: #363A43!important;
  padding: 15px;
  line-height: 100%;
  font-size: 0.8em;
}

.header-account-dropdown__link:hover {
  color: #F57500!important;
}

.header-account-dropdown__divider {
  height: 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
