.checkout-index-index {

  .modal-custom.authentication-dropdown {
    .modal-content {
      padding: 1.5rem;
    }
    .block-title {
      display: none;
    }
  }

  .page-title-wrapper {
    @include xy-grid();
    @include xy-gutters($negative: true);

    .page-title,
    .authentication-wrapper {
      @include xy-cell(6);
    }
  }

  background: get-color(white);

  // layout
  .opc-summary-wrapper {
    order: 1;
  }

  .checkout-container > .message {
    order: 2;
  }

  .opc-wrapper {
    order: 3;
  }

  @include breakpoint(medium) {
    .checkout-container > .message {
      order: 1;
    }
    .opc-wrapper {
      order: 2;
    }
    .opc-summary-wrapper {
      order: 3;
    }
  }

  .information-symbol {
    width: 20px;
  }

  .checkout-container > .message {
    span {
      vertical-align: middle;
    }
  }

  .no-quotes-block {
    margin-top: 10px;
  }

  .description {
    &:before {
      content: "- ";
    }
  }

  .page-header {

    .nav-toggle {
      display: none !important;
    }

    .logo {
      text-align: left !important;
      @include breakpoint(small only) {
        margin: 0 !important;
      }
    }

    .backlink {
      i {
        margin-right: 10px;
      }

      .text {
        display: inline-block;
        font-size: 0.875rem;
      }

      @include breakpoint(medium) {
        @include xy-cell(4);
        text-align: right;
      }
    }
  }

  .estimated-block {
    display: none;
  }

  table tbody tr {
    background: $almost-white;
  }

  .shipping-tooltip {
    border-bottom: none;
  }

  .step-title {
    @extend .h4;
  }
}

.opc-estimated-wrapper {
  display: none;
}

.opc-summary-wrapper .modal-header .action-close {
  display: none;
}

.opc-wrapper ol {
  list-style: none;
  margin-left: 0;
}

.opc-progress-bar-item._active:before {
  background-color: #F57500;
  color: get-color(white);
}

.opc-progress-bar {
  margin: 2rem 0;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: 1px;
    background: #cfd3dc;
    z-index: 1;
  }

  li {
    list-style: none;
    display: inline-block;
    padding: 0 60px 0 25px;
    position: relative;
    margin-left: 0;
    background: get-color(white);

    &:before {
      position: absolute;
      top: -2px;
      left: 0;
      z-index: 2;
      display: block;
      width: 25px;
      height: 25px;
      border-radius: 25px;
      background: #cfd3dc;
      color: $black;
      text-align: center;
      font-size: .725rem;
      font-weight: bold;
      line-height: 25px;
    }

    &:first-child:before {
      content: '1';
    }

    &:last-child:before {
      content: '2';
    }

    &:last-child {
      padding: 0 0 0 25px;
    }

    span {
      font-weight: bold;
      padding: 0 10px;
      background: get-color(white);
      position: relative;
      z-index: 2;
    }
  }
}

.card-divider {
  color: get-color(white);
  font-weight: bold;
  margin-bottom: 0;
  @extend .h4;
  line-height: 1;
}

.card-section {
  background: $almost-white;
  font-size: 0.875rem;
}

.checkout-shipping-method {
  .table-checkout-shipping-method {
    thead, tr.row {
      background: get-color(white);
      border-bottom: 1px solid $light-gray;
    }

    thead {
      display: none;
    }

    input {
      margin: 0;
    }
  }

  button.continue {
    @extend .cta, .large;
    float: right;
  }
}

////////////
// Step 2 //
////////////

.checkout-container {
  button.checkout {
    @extend .cta, .large;
    float: right;
    border-radius: 7px;
  }
}

.payment-option {
  font-weight: bold;
  font-size: .875rem;

  &.discount-code {
    clear: both;
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    margin-bottom: 1rem;
  }

  &.giftcardaccount {
    display: none;
    clear: both;
    border-bottom: 1px solid $light-gray;
    border-top: 1px solid $light-gray;
  }
}

.payment-option-title {
  padding: 1.25rem 0;
}

.payment-method {
    .adyen-checkout__checkbox__label {
        padding-left: 5px;
    }

    .adyen-checkout__checkbox__input+.adyen-checkout__checkbox__label{
        &:after,
        &:before {
            display: none;
        }
    }

    label[for="adyen_cc"] {
        padding-right: 80px;
        background-repeat: no-repeat;
        background-position: center right;
        background-image: url("../Adyen_Payment/images/credit-cards.png");
        background-size: contain;
    }

    label[for="adyen_swish"] {
      padding-right: 40px;
      background-repeat: no-repeat;
      background-position: center right;
      background-image: url("../Adyen_Payment/images/logos/swish.png");
      background-size: contain;
    }
}

.table-totals {
  text-align: left;

  .table-caption {
    display: none;
  }
}

/////////////
// Success //
/////////////

.checkout-onepage-success {
  .page-title-wrapper {
    text-align: center;

    .print {
      width: 100%;
    }
  }

  .checkout-success {
    text-align: center;
  }
}
