._collapsible .action-toggle:after,
.collapsible .collapsible__title:after {
  @include material-icon('keyboard_arrow_down');
  position: absolute;
  transition: transform .5s;
}


._collapsible._active .action-toggle:after,
.collapsible.active .collapsible__title:after {
  transform: rotate(180deg);
}
