.products-grid {
    .product-item-info {
        .product-image-container {
            width:180px;

            .product-image-wrapper {
                padding-bottom: 100%;
            }
        }
        .product-item-details {
            padding-top: 1rem;
        }
    }
}

.product-image-wrapper.carousel {
    margin-bottom: 1rem;
}
