.table-wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
}

.product-wrapper {
    @include xy-grid();
    margin-left: -1rem;
    margin-right: -1rem;

    padding-bottom: 0.5rem;
    border-bottom: 2px solid $medium-gray;
    margin-bottom: 0.5rem;

    @include breakpoint(large) {
        margin-bottom: 0.5rem;
    }

    .product-item-image {
        @include xy-cell(4);
    }

    .product-item-details {
        @include xy-cell(8);

        position: relative;
    }
}

.cart.main.actions {
    margin-bottom: 1rem;

    @include breakpoint(large) {
        margin-bottom: 0;
    }

    .action.continue {
        display: none;
    }

    .action {
        @include button-lybe();

        @include breakpoint(small only) {
            @include button-lybe($expand: true);
        }
    }

    .action.clear {
        margin-bottom: 1rem;

        @include breakpoint(medium) {
            margin-bottom: 0;
        }
    }
}
