@mixin button-lybe(
    $expand: false,
    $background: get-color(primary),
    $size: null,
    $background-hover: null,
    $color: auto,
    $style: solid
) {
    @if $background-hover == null {
        $background-hover: $background;
    }

    @include button($expand, $background, lighten($background-hover, $button-background-hover-lightness), $color, $style);
    border-radius: 7px;
    margin-bottom: 0;

    @if $size != null {
        font-size: map-get($button-sizes, $size);
    }

    &:not(.close-button):not(.action-close) {
        font-weight: $global-weight-bold;
    }

    &:hover {
        box-shadow: 0 6px 15px rgba($background, 0.45);
    }

    &:disabled {
        background: $light-gray;
        cursor: not-allowed;
    }

    &:disabled:hover {
        box-shadow: none;
    }
}

@mixin scout-button(
   $background: get-color(primary),
   $size: "big",
   $color: $white,
) {
    background: $background;
    margin-bottom: 0;
    border-radius: 10px;
    color: $color;
    display: inline-block;
    white-space: nowrap;

    @if $size == "big" {
        padding: 0.8rem 1.5rem;
        font-size: 1rem;

        @include breakpoint(medium) {
            padding: 1rem 2.5rem;
            font-size: 1.5rem;
        }
    }
    @else if($size == "small") {
        padding: 0.8rem 1.5rem;
        font-size: 1rem;

        @include breakpoint(medium) {
            font-size: 1.25rem;
        }
    }

    &.disabled {
        background: $light-gray;
        cursor: not-allowed;
    }
}

button {
    @include button-lybe();
}

.out-of-stock-btn {
    @include button-lybe(true, get-color(secondary));
}

.configurable-btn {
    @include button-lybe(true, get-color(stone));
}

.scout-button {
    @include scout-button;

    &.alt {
        @include scout-button(get-color(secondary));
    }

    &.white {
        @include scout-button(get-color($white), "big", get-color(primary));
    }

    &.small {
        &.alt {
            @include scout-button(get-color(secondary), "small");
        }

        &.white {
            @include scout-button(get-color($white), "small", get-color(primary));
        }

        @include scout-button(get-color(primary), "small");
    }
}

[type=submit],
[type=button] {
    border-radius: 7px;
}
