.block-mobile-search-icon {
    margin: 0 1.25rem 0 1.25rem;

    @include breakpoint(medium) {
        display: none;
    }
}

.search-icon-trigger {
    @include breakpoint(medium) {
        display: none;
    }
}

.block-search {
    .block-title {
        display: none;
    }

    .magnifier-lens {
        content: $icon-search;
    }

    form {
        padding: 0 10px 10px;
        position: relative;
        width: 100%;

        .label {
            display: none;
        }

        .field.search {
            @include xy-cell-base(auto); // TBR? used?

            input {
                height: 50px;
                margin: 0;
                border: none;
                border-radius: 7px;
            }

        }

        .actions {
            position: absolute;
            z-index: 20;
            right: 0;
            top: 0px;

            button {
                display: none;
                box-shadow: none;
                margin: 0;

                > span {
                    display: none;
                }
            }
        }

        .action {
            &.advanced {
                display: none;
            }
        }
    }

    @include breakpoint(small only) {
        background-color: get_color('primary');
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 1;

        -webkit-transition: top .5s cubic-bezier(0.250, 0.250, 0.750, 0.750);
        -moz-transition: top .5s cubic-bezier(0.250, 0.250, 0.750, 0.750);
        -o-transition: top .5s cubic-bezier(0.250, 0.250, 0.750, 0.750);
        transition: top .5s cubic-bezier(0.250, 0.250, 0.750, 0.750);
        top: -300px;

        &.active {
            top: 100%;
        }

        form {

            .control {
                margin-right: 30px;
            }

            .field.search input {
                padding: 20px 10px;
                border-width: 0 0 1px 0;
                border-color: #d3d3d3;
            }

            .actions {
                top: 15px;
                margin-right: 15px;
            }
        }
    }

    @include breakpoint(medium) {
        @include xy-cell(5);
        order: 2;

        form {
            padding: 0;
        }
    }

    @include breakpoint(large) {
        @include xy-cell(4);
        @include xy-cell-offset(1);
    }
}
