.snackbar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 11;
    height: 60px;
    width: 100%;
    padding: 0.313rem;
    background: $footer-bg;
    color: #fff;
    box-shadow: 0 -5px 15px rgba(0,0,0,0.2);

    svg {
    	width: 30px;
    	height: auto;
    	display: block;
    	margin: 0 auto 5px;
    }
    span {
		font-size: 0.688rem;
		display: block;
	}
}

.snackbar__link {
	color: #ffffff
}

.snackbar__link:hover,
.snackbar__link:active,
.snackbar__link:visited
{
	color: #ffffff
}

.product-menu-trigger { cursor: pointer; } // wierdest fkn trigger fix. offCanvasLeft will not trigger without this hack in Safari or Chrome on iOS...
