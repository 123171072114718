.opc-summary-wrapper .opc-block-summary > .title {
    height: rem-calc(65px);
}

.items-in-cart > .minicart-items::after {
    background: none !important;
}

.grand.totals {
    th, td {
        font-size: 1rem;
    }
}

.opc-summary-wrapper {
    border: 1px solid $light-gray;
    background-color: $almost-white;

    margin-bottom: 1.25rem;
}

tr {
    border-bottom: none;
}

tbody th, tbody td {
    padding: 0.5rem 0 0;
    font-weight: normal;
}

table tbody {
    border: none;
}

.totals,
.totals-tax {
    .mark {
    }

    .amount {
        text-align: right;
    }
}

.grand.totals th,
.grand.totals td {
    padding-bottom: 0.5rem;
}

.grand.totals .price {
    color: $color-orange;
}

.items-in-cart {
    padding: 0;

    .collapsible__title:after {
        right: 1rem;
    }

    .title {
        position: relative;
        display: block;
        cursor: pointer;
        font-size: 1rem;

        div > span {
            font-size: .75rem;
            margin-bottom: rem-calc(10px);
        }
    }
}

.minicart-items {
    @import 'checkout-minicart-product-item';
    list-style: none;
    margin: 0;
    border-top: none;
}

.select-scoutgroup-form {
    label {
        font-weight: $subheader-font-weight;
    }
}
