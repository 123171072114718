&:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

input,
textarea,
select {
    border-color: $light-gray !important;
    border-radius: 7px !important;
}

.qty-wrapper button {
    margin-bottom: 0;
}

.checkout-shipping-method,
.checkout-shipping-address,
.checkout-payment-method,
.opc-summary-wrapper {
    border-color: $light-gray !important;
}

.table-checkout-shipping-method,
tbody,
.shipping-address-item,
.table-checkout-shipping-method .row {
    border: none !important;
}

.step-title {
    background: get-color(primary);
    color: $white;
}

.opc-wrapper .step-title,
.opc-summary-wrapper .opc-block-summary > .title,
.opc-sidebar .step-title {
    background: get-color(primary);
    color: get-color(white);
    padding: 1rem !important;
    font-size: 1.5rem !important;
    &:before {
        display: none;
    }
}

.shipping-address-item {
    padding-bottom: 10px !important;
}

@at-root {
    .field-tooltip .label {
        display: none;
    }
}
.opc-wrapper .step-title,
.opc-block-summary .title {
    margin-bottom: 1rem;
}
