.toolbar {
    @include xy-grid;
    border-bottom: 1px solid $border-color;
    border-top: 1px solid $border-color;
    padding-bottom: 1rem;
    padding-top: 1rem;

    .modes {
        @include hide;
    }

    .filter-button {
        @include xy-cell(auto, false);

        @include breakpoint(medium up) {
            display: none;
        }
    }

    .off-canvas {
        z-index: 999;

        .filter {
            padding: 20px;
        }
    }

    .toolbar-sorter {
        @include xy-grid();
        @include xy-cell(8);
        @include xy-cell-offset(4);

        .sorter-label,
        .sorter-options {
            @include xy-cell(auto);
            border-radius: 7px;
        }

        .sorter-action {
            @include xy-cell(1/6);
        }

        .sorter-label {
            @include hide();
        }

        label.middle {
            padding: 0.3rem 0;
            margin: 0 1rem 0 0;
        }

        select {
            margin-bottom: 0;
        }

        .sorter-action {
            margin-left: 10px;
            &:before {
                @include scout-icon($icon-chevron-up, $fontSize: rem-calc(16));
                line-height: 40px;
                vertical-align: middle;
            }
            &.sort {
                &-asc {
                    &:before {
                        content: $icon-chevron-up;
                    }
                }
                &-desc {
                    &:before {
                        content: $icon-chevron-down;
                    }
                }
            }
            span {
                display: none;
            }
        }
    }

    .toolbar-amount {
        margin-bottom: 0;
    }

    .field.limiter {
        @include hide;
    }

    .pages {
        text-align: center;

        .label {
            @include hide;
        }
    }

    @include breakpoint(large) {
        .toolbar-sorter {
            @include xy-cell(4);
            @include xy-cell-offset(8);
        }
    }
}
