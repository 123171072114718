.product-item-details {
    display: grid;
    grid-template-rows: repeat(3, auto) 1fr;
    grid-template-columns: 1fr repeat(2, auto);

    .product-item-name {
        grid-column: 1 / span 3;

        font-size: rem-calc(18px);
    }

    .product-item-sku {
        grid-column: 1 / span 3;
    }

    .item-options {
        grid-column: 1 / span 3;
    }

    .price {
        grid-column: 3 / last;
        grid-row: 4 / last;
        align-self: end;

        color: $color-orange;
        font-weight: bold;

        @include breakpoint(medium) {
            font-size: rem-calc(20px);
        }
    }

    .fieldset {
        grid-column: 1;
        grid-row: auto / last;
        align-self: end;

        @import 'cart-item-qty-controls';
    }

    .actions-toolbar {
        grid-column: 4 / last;
        grid-row: 1;
        justify-self: end;

        .action-delete {
            font-size: 1.2rem;
        }
        .action-edit {
            display: none;
        }
    }
}
