@mixin pagination() {
    margin: 0;
    font-weight: $global-weight-bold;
    list-style: none;
    position: relative;

    li {
        display: inline-block;
        text-align: center;
        padding: .5rem;

        &:first-child:not(.current),
        &:last-child:not(.current) {
            position: absolute;
        }

        &:first-child:not(.current) {
            left: 0;
        }

        &:last-child:not(.current) {
            right: 0;
        }

        .action {
            &.previous {
                &:before {
                    @include scout-icon($icon-caret-left, $fontSize: rem-calc(12));
                }
            }
            &.next {
                &:after {
                    @include scout-icon($icon-caret-right, $fontSize: rem-calc(12));
                }
            }
        }

        .action.previous,
        .action.next,
        .page {
            display: inline-block;
            transition: border 0.15s ease-out;
        }

        i {
            position: relative;
            top: 7px;
        }
    }
}
