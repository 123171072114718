
.footer-customer-club {
  margin-bottom: 1.7rem;
  // @extend .grid-x, .grid-margin-x;
  @include xy-grid;
  @include xy-gutters($negative: true);
}

.footer-customer-club .newsletter form.subscribe {
  @extend .grid-x;
}

.footer-customer-club .field.newsletter {
  @extend .medium-9;
}

.footer-customer-club .actions .subscribe {
  @extend .auto, .cell;
  color: #ffffff;
}

.footer-customer-club .actions .subscribe:hover {
  color: #ffffff;
}


.footer-customer-club .block.newsletter,
.footer-customer-club .customer-club {
  @include xy-cell;
  @include breakpoint(medium) {
    @include xy-cell(6);
  }
}

.corporate-link-wrapper {
  margin-top: 1rem;
}
