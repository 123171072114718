table {
    margin: 0;

    thead {
        background-color: get-color(primary);
        color: get-color(white);
        font-size: 1rem;
        border: none;

        @include breakpoint(medium) {
            font-size: 1rem;
        }

        tr th {
            padding: 1rem 0.5rem;
            line-height: 1;
        }
    }

    tfoot tr th {
        padding: 0.25rem 0.625rem;
    }

    tr {
        word-break: break-word;
        hyphens: auto;
        font-size: 1rem;

        td {
            font-size: rem-calc(12px);
            padding: 0.5rem;
        }
    }

    .actions {
        text-align: right;

        .action.order {
            @include button-lybe($size: small, $style: hollow);
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            margin-left: 0.5rem;
        }
    }
}
