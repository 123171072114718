.company-switcher-list {
  .company-switcher-list-item {
    .company-name {
      display: block;
      &.link {
        color: $anchor-color;
        &:hover {
          color: $anchor-color-hover;
        }
      }
      &.current {
        color: $light-gray;
      }
    }
  }
}
