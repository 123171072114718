/*
 * 	Default theme - Owl Carousel CSS File
 */

$owl-color-base: #869791 !default;
$owl-color-white: #FFF !default;
$owl-color-gray: #D6D6D6 !default;

//nav

$owl-nav-color: $owl-color-white !default;
$owl-nav-color-hover: $owl-color-white !default;
$owl-nav-font-size: 14px !default;
$owl-nav-rounded: 3px !default;
$owl-nav-margin: false;
$owl-nav-padding: false;
$owl-nav-background: false;
$owl-nav-background-hover: false;
$owl-nav-disabled-opacity: 0.5 !default;

//dots

$owl-dot-width: 10px !default;
$owl-dot-height: 10px !default;
$owl-dot-rounded: 30px !default;
$owl-dot-margin: 5px 7px !default;
$owl-dot-background: $owl-color-gray !default;
$owl-dot-background-active: $owl-color-base !default;

$owl-image-path: "" !default;

/*
 *  Owl Carousel - Core
 */
.owl-carousel {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    /* position relative and z-index fix webkit rendering fonts issue */
    position: relative;
    z-index: 1;

    .owl-stage {
        position: relative;
        -ms-touch-action: pan-Y;
        touch-action: manipulation;
        -moz-backface-visibility: hidden;
        /* fix firefox animation glitch */
    }

    .owl-stage:after {
        content: ".";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0;
    }

    .owl-stage-outer {
        position: relative;
        overflow: hidden;
        /* fix for flashing background */
        -webkit-transform: translate3d(0px, 0px, 0px);
    }

    .owl-wrapper,
    .owl-item {
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
    }

    .owl-item {
        position: relative;
        min-height: 1px;
        float: left;
        -webkit-backface-visibility: hidden;
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
    }

    .owl-item img {
        display: block;
        width: 100%;
    }

    .owl-nav.disabled,
    .owl-dots.disabled {
        display: none;
    }

    .owl-nav .owl-prev,
    .owl-nav .owl-next,
    .owl-dot {
        cursor: pointer;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .owl-nav button.owl-prev,
    .owl-nav button.owl-next,
    button.owl-dot {
        color: inherit;
        border: none;
        font: inherit;
    }

    &.owl-loaded {
        display: block;
    }

    &.owl-loading {
        opacity: 0;
        display: block;
    }

    &.owl-hidden {
        opacity: 0;
    }

    &.owl-refresh .owl-item {
        visibility: hidden;
    }

    &.owl-drag .owl-item {
        touch-action: pan-y;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    &.owl-grab {
        cursor: move;
        cursor: grab;
    }

    &.owl-rtl {
        direction: rtl;
    }

    &.owl-rtl .owl-item {
        float: right;
    }
}

/* No Js */
.no-js .owl-carousel {
    display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel {
    .animated {
        animation-duration: 1000ms;
        animation-fill-mode: both;
    }

    .owl-animated-in {
        z-index: 0;
    }

    .owl-animated-out {
        z-index: 1;
    }

    .fadeOut {
        animation-name: fadeOut;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/*
 * 	Owl Carousel - Auto Height Plugin
 */

.owl-height {
    transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */

.owl-carousel {
    .owl-item {
        .owl-lazy {
            opacity: 0;
            transition: opacity 400ms ease;
        }

        /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */
        .owl-lazy[src^=""],
        .owl-lazy:not([src]) {
            max-height: 0;
        }

        img.owl-lazy {
            transform-style: preserve-3d;
        }
    }
}

/*
 * Owl Theme
 */

.owl-theme {

    // Styling Next and Prev buttons
    .owl-nav {
        margin-top: 10px;
        text-align: center;
        -webkit-tap-highlight-color: transparent;

        [class*='owl-'] {
            color: $owl-nav-color;
            font-size: $owl-nav-font-size;
            display: inline-block;
            cursor: pointer;
            border-radius: 3px;
            margin: 0 0.5rem;

            @if $owl-nav-margin {
                margin: $owl-nav-margin;
            }
            @if $owl-nav-padding {
                padding: $owl-nav-padding;
            }
            @if $owl-nav-background {
                background: $owl-nav-background;
            }
        }

        .disabled {
            opacity: $owl-nav-disabled-opacity;
            cursor: default;
        }
    }

    // Styling dots
    .owl-nav.disabled+.owl-dots {
        margin-top: 10px;
    }

    .owl-dots {
        text-align: center;
        -webkit-tap-highlight-color: transparent;

        .owl-dot {
            display: inline-block;
            zoom: 1;
            *display: inline;

            span {
                width: $owl-dot-width;
                height: $owl-dot-height;
                margin: $owl-dot-margin;
                background: $owl-dot-background;
                display: block;
                -webkit-backface-visibility: visible;
                transition: opacity 200ms ease;
                border-radius: $owl-dot-rounded;
            }

            &.active,
            &:hover {
                span {
                    background: $owl-dot-background-active;
                }
            }
        }
    }
}

/*
 * 	Owl Carousel - Video Plugin
 */

.owl-carousel {
    .owl-video-wrapper {
        position: relative;
        height: 100%;
        background: #000;
    }

    .owl-video-play-icon {
        position: absolute;
        height: 80px;
        width: 80px;
        left: 50%;
        top: 50%;
        margin-left: -40px;
        margin-top: -40px;
        background: url("#{$owl-image-path}owl.video.play.png") no-repeat;
        cursor: pointer;
        z-index: 1;
        -webkit-backface-visibility: hidden;
        transition: transform 100ms ease;
    }

    .owl-video-play-icon:hover {
        transform: scale(1.3, 1.3);
    }

    .owl-video-playing .owl-video-tn,
    .owl-video-playing .owl-video-play-icon {
        display: none;
    }

    .owl-video-tn {
        opacity: 0;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        transition: opacity 400ms ease;
    }

    .owl-video-frame {
        position: relative;
        z-index: 1;
        height: 100%;
        width: 100%;
    }
}
