.required-symbol {
  color: get-color(alert);
}
.has-error {
  background-color: get-color(alert);
}

[type=text],
[type=password],
[type=date],
[type=datetime],
[type=datetime-local],
[type=month],
[type=week],
[type=email],
[type=number],
[type=search],
[type=tel],
[type=time],
[type=url],
[type=color],
textarea {
    border-radius: 7px;
}
