.hamburger-menu {
    height: 20px;
    position: relative;
    width: auto;

    span {
        background: $white;
        border-radius: 9px;
        display: block;
        height: 3px;
        opacity: 1;
        left: 0;
        position: absolute;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
        width: 27px;

        &:nth-child(1) {
            top: 0px;
        }

        &:nth-child(2) {
            top: 8px;
        }

        &:nth-child(3) {
            top: 16px;
        }
    }
}

.nav-open .hamburger-menu,
.hamburger-menu.open {
    span {
        &:nth-child(3) {
            top: 7px;
            -webkit-transform: rotate(-135deg);
            -moz-transform: rotate(-135deg);
            -o-transform: rotate(-135deg);
            transform: rotate(-135deg);
        }

        &:nth-child(2) {
            opacity: 0;
        }

        &:nth-child(1) {
            top: 7px;
            -webkit-transform: rotate(135deg);
            -moz-transform: rotate(135deg);
            -o-transform: rotate(135deg);
            transform: rotate(135deg);
        }
    }
}
