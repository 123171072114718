@mixin tax-switcher {
  // Tax switcher top right in header
  .tax-switcher-wrapper {
    float: right;
  }
  .tax-switcher-form-wrapper {
    display: none;
  }
  .tax-switcher-buttons-wrapper {
    top: 1px;
    height: 29px;
    width: 150px;
    border-radius: 100px;
    background-color: #494D56;
    position: relative;
    border: 2px solid #494D56;
  }
  .tax-switcher-button {
    width: 72px;
    background: none !important;
    border: none !important;
    float: left;
    font-size: 12px !important;
    display: block;
    margin: 0px !important;
    padding: 0px !important;
    line-height: 25px !important;
    position: absolute;
    z-index: 1;
    right: 0px;
  }
  .tax-switcher-button:hover {
    box-shadow: none !important;
  }
  .tax-switcher-button:first-child {
    left: 0px;
  }
  #tax-switcher-bg {
    height: 25px;
    width: 76px;
    border-radius: 100px;
    background-color: #2B2E37;
    position: absolute;
    z-index: 0;
  }
  .tax-switcher-bg-1 {
    left: auto;
    right: 0px;
  }
  .tax-switcher-bg-2 {
    left: 0px;
    right: auto;
  }
}
