.minicart-wrapper {
    color: #363A43;

    .totals-external {
        @include show-for(medium);
        display: inline-block;
        color: white;
        margin-right: 0.5rem;
        margin-top: 0.18rem;
    }

    .showcart {
        @include breakpoint(small only) {
            display: block;
        }
    }

    .showcart > img {
        @include breakpoint(small only) {
            margin-left: auto;
            margin-right: auto;
            display: block;
        }
    }

    #top-cart-btn-checkout {
      @include button-lybe(true, get-color(cta))
    }
}

.block-minicart {
    background: get-color(white);
    border: 1px solid $light-gray;
    box-sizing: border-box;
    box-shadow: 0 7px 13px -5px rgba(0, 0, 0, 0.3);
    color: $menu-item-color;
    display: none;
    font-size: .875rem;
    list-style: none none;
    margin: 4px 0 0 0;
    min-width: 100%;
    padding: 2.75rem 0 0.25rem;
    position: absolute;
    right: -20px;
    top: 100%;
    width: 390px;
    z-index: 100;

    &:after,
    &:before {
        border-bottom-style: solid;
        content: '';
        display: block;
        height: 0;
        position: absolute;
        width: 0;
    }

    &:before {
        border: 6px solid;
        border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) get-color(white) rgba(0, 0, 0, 0);
        z-index: 99;
        top: -12px;
        left: auto;
        right: 26px;
    }

    &:after {
        border: 7px solid;
        border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #bbb rgba(0, 0, 0, 0);
        z-index: 98;
        top: -14px;
        left: auto;
        right: 25px;
    }

    .block-title {
        display: none;
    }

    button.close {
        position: absolute;
        top: 0.25rem;
        right: 1rem;
        font-size: 2rem;
        color: $medium-gray;
        cursor: pointer;
        font-weight: normal;
        padding: 0;

        &:hover {
            color: $black;
            box-shadow: none;
        }

        &:focus {
            padding: 0;
        }
    }

    .items-total {
        float: left;
        padding-left: 30px;
        width: 50%;
        text-align: left;

        .count {
            font-weight: $global-weight-bold;
        }
    }

    .subtotal {
        float: right;
        padding-right: 30px;
        text-align: right;
        width: 50%;

        .label {
            background: transparent;
            border-radius: 0;
            color: inherit;
            display: block;
            font-weight: normal;
            font-size: inherit;
            line-height: inherit;
            margin-bottom: 0;
            padding: 0;
            text-transform: inherit;
        }

        .price-container {
            color: $color-orange;
            display: inline;
            font-size: rem-calc(18);
            font-weight: $global-weight-bold;
        }
    }

    .block-content {
        .subtitle {
            &.empty {
                display: block;
                font-size: 14px;
                padding: 30px 0 20px;
                text-align: center;
            }
        }

        & > .actions {
            padding: 1rem;
            text-align: center;
        }
    }

    .actions {
        clear: both;
        .primary {
            .button {
                margin: 0;
            }
        }
    }

    .action {
        &.close {
            background: none;
            & > span {
                border: 0;
                clip: rect(0, 0, 0, 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }

            &:before {
                @include scout-icon($icon: $icon-close, $fontSize: 14px);
            }
        }

        &.viewcart {
            @include button-lybe($expand: true, $background: $color-orange, $color: get-color(white));
            margin-bottom: 0;
        }
    }

    .subtitle {
        display: none;
    }

    .minicart-items {
        height: inherit;
        border: none;
        box-shadow: -2px 0px 4px 4px rgba(50, 50, 50, 0.1) inset;
        margin: 0;
    }

    .overflowed .minicart-items {
        overflow-y: scroll;
    }
}
