.box {
    margin-bottom: rem-calc(20px);
    border: 1px solid $medium-gray;

    .box-title-box {
        padding: 0.5rem 1rem;
        color: get-color(white);
        background-color: get-color(primary);
        text-transform: uppercase;
    }

    .box-title {
        display: flex;
        justify-content: space-between;
    }

    .edit {
        display: inline-block;
        color: get-color(white);
    }

    .box-content {
        padding: rem-calc(10px) rem-calc(10px) rem-calc(15px);
        address {
            font-style: normal;
        }
    }
}
