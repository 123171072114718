._with-tooltip {
    .field-tooltip {
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;

        .field-tooltip-action {
            display: block;
            width: 20px;
            height: 20px;
        }

        .action-help {
            &:before {
                display: block;
            }
        }

        .field-tooltip-content {
            display: none;
        }

        &._active {
            .field-tooltip-content {
                display: block;
                position: absolute;
                right: -7px;
                top: 28px;
                border: 1px solid $light-gray;
                padding: 0.65rem;
                z-index: 9;
            }
        }
    }
}

.ui-tooltip {
    position: absolute;

    .ui-tooltip-content {
        background-color: $tooltip-background-color;
        color: $tooltip-color;
        padding: $tooltip-padding;
        max-width: $tooltip-max-width;
        font-size: $tooltip-font-size;
        border-radius: $tooltip-radius;
    }
}
