.fieldset {
    .field {
        &.choice {
            label {
                white-space: initial;
                margin-right: 0;
                width: calc(100% - 2.7rem);
            }
        }

        &:not(.choice) {
            label {
                @include form-label();
            }
        }
    }

    &.password {
        grid-row: 2;
        grid-column: 1/3;

        background: $almost-white;
        box-shadow: 0 0 5px 4px inset rgba(10, 10, 10, 0.05);
        padding: 2rem;
        margin: 0 -2rem 1rem;

        @include breakpoint(large) {
            margin: 0 0 1rem;
        }

        .control {
            margin-bottom: 1rem;
        }
    }

    &.create.account {
        p {
            margin: 0;
        }
    }
}
