@mixin filter-header {
    .button.toggle-filter {
        display: none;

        @include breakpoint(medium up) {
            display: block;
        }

        height: 40px;
        color: get-color(white);
        margin-bottom: 0;

        &:hover {
            color: get-color(white) !important;
        }

        img {
            margin-right: 10px;
        }
    }
}

@mixin filter-content {

    // semantic declaration of accordion
    .filter-options {
        @include accordion-container;

        .filter-item {
            @include accordion-item;

            .filter-options {
                &-title {
                    @include accordion-title;
                }

                &-content {
                    @include accordion-content;
                }
            }
        }
    }

    .items {
        margin-left: 0;
        list-style: none;
    }

    .swatch-option-link-layered {
        display: block;

        .swatch-option {
            float: unset;
            display: inline-block;
            margin: 0;
        }
    }

    @include breakpoint(medium up) {
        position: relative;

        &.active {
            max-height: 2000px;
        }

        .filter-current {
            .filter-state-remove {
                display: block;
            }

            .filter-label,
            .filter-value {
                color: $black;
                font-size: 0.875rem;
            }
        }

    }

    .filter-actions {
        margin-bottom: 1rem;
        a {
            @include button(true, get-color(stone), $black);
            margin-bottom: 0;
        }
    }

    .filter-options {
        margin: 0;
        border: none;

        .filter-item {
            .filter-options-title {
                border-bottom: 1px solid $accordion-border-color;
                border-top: 1px solid $accordion-border-color;
                padding-left: rem-calc(16);
                padding-right: rem-calc(16);

                &:before {
                    @include scout-icon($icon-chevron-down, $fontSize: rem-calc(12));
                    color: $medium-gray;
                    transition: transform 0.4s;
                }

                &.active {
                    &:before {
                        transform: rotate(180deg);
                    }
                }
            }

            .filter-options-title.active + .filter-options-content {
                display: block;
            }

            .filter-options-content {
                border-top: 1px solid $border-color;
                padding-bottom: rem-calc(5);
                padding-left: rem-calc(16);
                padding-right: rem-calc(16);
                padding-top: rem-calc(5);
            }

            li {
                font-size: rem-calc(15);
                line-height: 2.5;

                a {
                    color: $accordion-item-color;
                }

                .count {
                    float: right;
                    &:before {
                        display: inline;
                        content: "(";
                    }
                    &:after {
                        display: inline;
                        content: ")";
                    }

                    .filter-count-label {
                        display: none;
                    }
                }
            }

            &.is-active {
                .filter-options-title {
                    &:before {
                        content: $icon-chevron-up;
                    }
                }
            }

            &:last-child {
                border-bottom: 1px solid $accordion-border-color;
            }
        }
    }
}
