.footer-store-selector__label {
  font-size: 1.25em;
  margin-right: 0.7rem;
}

// TODO: Fix this without important. Scope footer better.
.footer-store-selector__icon {
  width: 2.25rem;
  height: 2.25rem;
  margin-top: -7px;
}

.footer-mystore__store-name {
  font-weight: bold;
  font-size: 1.1em;
}

.footer-mystore__store-address-link {
  text-decoration: underline;
}


.indicator-circle {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-right: 10px;
  margin-top: 5px;
}

.store-stock-indicator {
  display: inline-block;
}
