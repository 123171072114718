.modal-header {
    margin-bottom: 0.5rem;

    @include breakpoint(large) {
        margin-bottom: 1rem;
    }
}

.modal-title {
    font-size: 1.5rem;
    display: block;
    color: $color-shark;
    font-weight: bold;
    margin-bottom: 0;
    min-height: 1em;
    word-wrap: break-word;
}
