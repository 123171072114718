@mixin googlemap($btnMarigns) {
    button {
      @include breakpoint(small only) {
        margin-right: $btnMarigns !important;
      }
    }
    .gm-bundled-control {
      display: none;
      @include breakpoint(medium up) {
        display: block;
      };
    }
}

// TBD?
.googlemap {
  .location-box-view {
    display: none;
  }
}
