// ==========================================================
// = ¡¡¡Banner that declares what type of file I look at!!! =
// ==========================================================

//
// @TODO: Add docs to toast!
//
@mixin toast {
  $toast-height: 60px;
  $toast-padding: 20px 30px 15px;
  $toast-line-height: 1.6;
  $toast-font-size: 1rem;
  $toast-bg: get-color(primary);
  $toast-color: get-color(white);
  $toast-shadow-color: rgba(get-color(stone), 0.15);
  $toast-shadow-settings: 0 5px 10px 6px;

  &:before {
    content: '';
    display: block;
    vertical-align: middle;
    margin-top: -4px;
    margin-right: 10px;
  }

  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  background: $toast-bg;
  color: $toast-color;
  line-height: $toast-line-height;
  padding: $toast-padding;
  min-height: $toast-height;
  min-width: 288px; // TODO ? percentage?
  width: 100%;
  //max-width: 568px; // TODO ? percentage?
  pointer-events: none;
  overflow: hidden;
  opacity: 0;
  bottom: -$toast-height;
  z-index: 9999;

  @include shadow($toast-shadow-color $toast-shadow-settings);

  transition: opacity 0.3s ease-out;
  transition: bottom 0.5s ease-out;

  &.toaster-visible {
    opacity: 1;
    bottom: 0;
  }
}

#toaster {
  @include toast;
  // only show last message
  .messages {
    display: none;

    &:last-child() {
      display: block;
      .message {
        display: none;
        &:last-child() {
          display: block;
        }
      }
    }
  }
  // color highlighting (for later use)
  &.alert {
    background: get-color(alert);
  }

  &.warning {
    background: get-color(warning);
  }

  &.success {
    background: get-color(success);
  }

  &.cta {
    background: get-color(cta);
  }
}
