label {
    @include form-label();
}

.actions-toolbar {
    display: grid;
    align-items: flex-end;
    grid-template-columns: auto 1fr;
    gap: 0.5rem;

    .secondary {
        line-height: 1;
    }
}
