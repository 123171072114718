.form-create-account {
    .field {
        &:not(.choice) {
            label {
                @include form-label();
            }
        }
    }

    .control {
        background: $almost-white;
        margin-bottom: 1rem;

        input {
            margin-bottom: 0.5rem;
        }

        .mage-error:not(input) {
            padding: 0.5rem;
            display: list-item;
            margin-left: 2rem;

            &:after {
                content: '';
                display: block;
                padding-bottom: 10px;
            }
        }
    }

    // specific fields

    .newsletter {
        margin-bottom: 1rem;

        label {
            white-space: initial;
            vertical-align: middle;
            margin-right: 0;
            margin-bottom: 0;
            width: calc(100% - 2.7rem);
        }
    }

    .scout-group {
        #scout_group {
            margin-bottom: 0;
            border-radius: 7px;
        }
    }

    .password {
        .control {
            #password-error,
            #password-strength-meter-container {
                padding: 0.5rem;
                display: list-item;
                margin-left: 2rem;
            }

            #password-strength-meter-container {
                &.password-weak {
                    #password-strength-meter-label {
                        color: get-color(secondary);
                    }
                }

                &.password-very-strong {
                    #password-strength-meter-label {
                        color: get-color(primary);
                    }
                }

                #password-strength-meter-label {
                    font-weight: 500;
                }
            }

            .password-strength-meter {
                padding-bottom: 10px;
            }
        }
    }

    @import '../../captcha';

    .actions-toolbar {
        @include breakpoint(small only) {
            margin-top: 1rem;
            button {
                @include button-lybe($expand: true);
            }
        }
    }
}

