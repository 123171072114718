@mixin sidebar-navigation {
    dt {
        border-top: 1px solid $accordion-border-color;
        font-size: rem-calc(15);
        font-weight: normal;
        line-height: 1;
        margin-bottom: 0;
        padding: 0.75rem 1rem;

        &:before {
            @include scout-icon($icon-chevron-down, $fontSize: rem-calc(12));
            color: $medium-gray;
            float: right;
        }
    }

    dl {
        .items {
            background: $color-polar;
            border-top: 1px solid $accordion-border-color;
            padding-bottom: rem-calc(5);
            padding-left: rem-calc(16);
            padding-right: rem-calc(16);
            padding-top: rem-calc(5);

            li {
                font-size: rem-calc(15);
                line-height: 2.5;
            }

            a {
                color: $accordion-item-color;
            }

            .count {
                float: right;
                &:before {
                    display: inline;
                    content: "(";
                }
                &:after {
                    display: inline;
                    content: ")";
                }

                .filter-count-label {
                    display: none;
                }
            }
        }
    }
}
