.account-nav {
    border: 1px solid $border-color;
    padding-top: 1rem;
    margin: 0 1rem 0.5rem;

    @include breakpoint(large) {
        border: transparent;
        padding-top: 0;
        margin: 1rem 0 0;
    }
}

.account-nav-content {
    .items {
        list-style: none;
        text-align: center;
        font-size: rem-calc(20px);
        margin: 0 0 1rem 0;

        li {
            padding: 5px;
        }
        @include breakpoint(large) {
            text-align: left;
            font-size: 1rem;

            li {
                padding: rem-calc(5px);
            }
        }
    }
}
