tr {
    .lastname,
    .city,
    .country,
    .phone {
        display: none;
    }

    @include breakpoint(medium) {
        .phone {
            display: table-cell;
        }
    }

    @include breakpoint(large) {
        .lastname,
        .city,
        .country {
            display: table-cell;
        }
    }
}
