.checkout-onepage-success {
    .checkout-success,
    #registration{
        padding: 1rem;

        h2 {
            color: get-color(primary);
        }

        @include breakpoint(large) {
            @include xy-grid(6)
        }
    }

    @include breakpoint(large) {
        .main {
            margin: 2rem 0 0;
            @include xy-grid();

        }
        .checkout-success,
        #registration{
            @include xy-cell(auto);
        }
    }

    .checkout-success {
        background-color: $almost-white;
        .action {
            @include button-lybe($style: hollow);
        }
    }

    #registration {
        text-align: center;

        .action {
            @include button-lybe();
        }
    }
}
