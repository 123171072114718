ol.minicart-items {
    li {
        padding: 1rem 1rem 2rem;
        border-bottom: 1px solid $light-gray;

        &.product {
            display: block;

            a {
                color: $black;
                font-weight: normal;

                &:hover {
                    color: $primary-color;
                    text-decoration: underline;
                }
            }

            > .product {
                display: flex;
                position: relative;
            }

            .product {
                font-size: rem-calc(16);

                &.actions {
                    text-align: right;
                    width: auto;
                }

                &-image-container {
                    display: block;
                    .product-image-wrapper {
                        @include breakpoint(small only) {
                            padding-bottom: 0 !important;
                        }
                    }
                }

                &-item-photo {
                    margin-right: 12px;

                    img {
                        border: 1px solid $light-gray;
                    }
                }

                &-item-name {
                    width: 100%;
                    display: inline-block;
                    a {
                        color: get-color(cta);
                        font-weight: $global-weight-bold;
                        white-space: break-spaces;
                    }
                }

                &-item-details {
                    @include text-truncate();
                    flex-grow: 1;

                    .actions {
                        clear: none;
                        float: right;
                        margin-top: 25px;
                        width: 50%;

                        .primary,
                        .secondary {
                            display: inline-block;
                            line-height: 1;
                            padding: 3px 10px 0;
                        }
                    }

                    .action {
                        &.edit {
                            span {
                                display: none;
                            }

                            &:before {
                                @include scout-icon($icon: $icon-edit, $fontSize: 18px);
                            }
                        }
                        &.delete {
                            span {
                                display: none;
                            }

                            &:before {
                                @include scout-icon($icon: $icon-trash, $fontSize: 18px);
                            }
                        }
                    }

                    &:after {
                        clear: both;
                        content: " ";
                        display: block;
                    }
                }

                &-item-pricing {
                    float: left;
                    width: 50%;

                    .price {
                        color: $color-orange;
                        font-weight: $global-weight-bold;
                    }
                }
            }

            .options {
                .toggle {
                    cursor: pointer;
                    &:after {
                        @include scout-icon($icon: $icon-chevron-down, $fontSize: 8px);
                        display: inline-block;
                        margin-left: 10px;
                        transition: transform .3s;
                    }
                }
                &.active {
                    .toggle {
                        &:after {
                            transform: rotate(180deg);
                        }
                    }
                }
            }

            .details-qty {
                position: absolute;
                display: flex;
                align-items: center;

                .qty-control-button {
                  margin: 0;
                  padding: 0;
                  line-height: 1.8rem;
                  padding-top: rem-calc(3px);
                  border-radius: 0.2rem;
                  width: 2rem;
                }

                input {
                    box-shadow: none;
                    margin: 0;
                    padding: rem-calc(4px) 0 0;
                    text-align: center;
                    width: 2rem;
                    line-height: 1.8rem;
                    height: auto;
                    border: none;
                    font-weight: 600;
                    color: get-color(primary);
                    font-size: 1rem;
                    background-color: transparent;
                }
            }
        }
    }
}
