._with-tooltip {
    position: relative;
}

.field-tooltip {
    cursor: pointer;
    top: 0;
    right: 2.5rem;
}

.field-tooltip-action {
    height: 3.125rem;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    box-sizing: content-box;

    &:before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iaS1pbmZvIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiBmaWxsPSJub25lIiBzdHJva2U9IiM1NTUiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2Utd2lkdGg9IjEiPgogICAgPHBhdGggZD0iTTE2IDE0IEwxNiAyMyBNMTYgOCBMMTYgMTAiIC8+CiAgICA8Y2lyY2xlIGN4PSIxNiIgY3k9IjE2IiByPSIxNCIgLz4KPC9zdmc+);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: contain;
    }
}
