$icon-caret-down: "\e919";
$icon-caret-left: "\e91a";
$icon-caret-right: "\e91b";
$icon-caret-up: "\e91c";
$icon-close: "\e900";
$icon-bag-yellow-path1: "\e901";
$icon-bag-yellow-path2: "\e902";
$icon-bag-yellow-path3: "\e903";
$icon-bag-path1: "\e904";
$icon-bag-path2: "\e905";
$icon-bag-path3: "\e906";
$icon-heart: "\e907";
$icon-user: "\e908";
$icon-trash: "\e909";
$icon-search: "\e90a";
$icon-edit: "\e90b";
$icon-ruler: "\e90c";
$icon-tag: "\e90d";
$icon-leaf: "\e90e";
$icon-check: "\e90f";
$icon-truck: "\e910";
$icon-star: "\e911";
$icon-chat: "\e912";
$icon-chevron-left: "\e913";
$icon-chevron-up: "\e914";
$icon-chevron-down: "\e915";
$icon-chevron-right: "\e916";
$icon-eco: "\e917";
$icon-donation: "\e918";
$icon-arrow-right: "\2192";
$icon-color-yellow: rgb(250, 194, 65);

@font-face {
    font-family: 'scout';
    src:
        url('../fonts/scout/scout.ttf?wssagm') format('truetype'),
        url('../fonts/scout/scout.woff?wssagm') format('woff'),
        url('../fonts/scout/scout.svg?wssagm#scout') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'scout' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.icon-caret-down {
    &:before {
        content: $icon-caret-down;
    }
}

.icon-caret-left {
    &:before {
        content: $icon-caret-left;
    }
}

.icon-caret-right {
    &:before {
        content: $icon-caret-right;
    }
}

.icon-caret-up {
    &:before {
        content: $icon-caret-up;
    }
}

.icon-close {
    &:before {
        content: $icon-close;
    }
}

.icon-bag-yellow {
    position: relative;
    .path1 {
        &:before {
            content: $icon-bag-yellow-path1;
            color: $icon-color-yellow;
        }
    }

    .path2 {
        &:before {
            bottom: 0;
            content: $icon-bag-yellow-path2;
            left: 0;
            position: absolute;
        }
    }

    .path3 {
        &:before {
            content: $icon-bag-yellow-path3;
            color: $icon-color-yellow;
            left: 0;
            position: absolute;
            top: 0;
        }
    }
}

.icon-bag {
    position: relative;

    .path1 {
        &:before {
            content: $icon-bag-path1;
            color: $white;
        }
    }

    .path2 {
        &:before {
            bottom: 0;
            content: $icon-bag-path2;
            color: $black;
            left: 0;
            position: absolute;
        }
    }

    .path3 {
        &:before {
            content: $icon-bag-path3;
            color: $white;
            left: 0;
            position: absolute;
            top: 0;
        }
    }
}

.icon-heart {
    &:before {
        content: $icon-heart;
    }
}

.icon-user {
    &:before {
        content: $icon-user;
    }
}

.icon-trash {
    &:before {
        content: $icon-trash;
    }
}

.icon-search {
    &:before {
        content: $icon-search;
    }
}

.icon-edit {
    &:before {
        content: $icon-edit;
    }
}

.icon-ruler {
    &:before {
        content: $icon-ruler;
    }
}

.icon-tag {
    &:before {
        content: $icon-tag;
    }
}

.icon-leaf {
    &:before {
        content: $icon-leaf;
    }
}

.icon-check {
    &:before {
        content: $icon-check;
    }
}

.icon-truck {
    &:before {
        content: $icon-truck;
    }
}

.icon-star {
    &:before {
        content: $icon-star;
    }
}

.icon-chat {
    &:before {
        content: $icon-chat;
    }
}

.icon-chevron-left {
    &:before {
        content: $icon-chevron-left;
    }
}

.icon-chevron-up {
    &:before {
        content: $icon-chevron-up;
    }
}

.icon-chevron-down {
    &:before {
        content: $icon-chevron-down;
    }
}

.icon-chevron-right {
    &:before {
        content: $icon-chevron-right;
    }
}

.icon-eco {
    &:before {
        content: $icon-eco;
    }
}

.icon-donation {
    &:before {
        content: $icon-donation;
    }
}

.icon-arrow-right {
    &:before {
        content: $icon-arrow-right;
    }
}
