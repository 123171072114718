.main {
    header {
        text-align: center;
        font-size: 2rem;
        padding-bottom: 1rem;
        font-weight: 500;
    }
}

.form-edit-account {
    @include breakpoint(medium) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }

    @import 'fieldsets';

    @import '../captcha';

    @import 'tooltip';

    .actions-toolbar {
        align-self: flex-end;

        button {
            width: 100%;
        }
    }

    #scout_group {
        border-radius: 7px;
    }
}
