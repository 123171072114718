@include breakpoint(small only) {
    .products.list {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(2, 1fr);
    }
}

@include breakpoint(large) {
    @include xy-grid-container();
}

@include price;

button.tocart {
    @include button-lybe($expand: true);
}

.product-item-photo {
    display: block;
    text-align: center;
}
