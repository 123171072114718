.order-links,
.order-title,
.table-caption {
    display: none;
}

.block-order-details-view {
    .block-title {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding: 0;
        margin-bottom: 1rem;

        .title {
            font-size: 2rem;
        }

        .order-number {
            .label {
                color: get-color(stone);
            }
            .number {
                font-weight: 500;
            }
        }
    }

    .block-content {
        @include breakpoint(medium) {
            display: grid;
            gap: 1rem;
            grid-template-columns: repeat(2, 1fr);
        }

        @import "../customer-info-box";
    }
}

.order-details-items {
    .items-qty {
        margin: 0;
        list-style: none;
    }
}
