.main {
    .block-title {
        text-align: center;
        font-size: 2rem;
        padding-bottom: 1rem;
    }
}

.block-addresses-default {
    @import '../customer-info-box';

    @include breakpoint(medium) {
        .block-content {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1rem;
        }
    }
}

.block-addresses-list {
    @import 'customer-address-table';
}

.actions-toolbar {
    button {
        @include breakpoint(small only) {
            @include button-lybe($expand: true)
        }
    }
}
