.payment-method-title {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    label {
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0;
        img {
            margin-right: 0.5rem;
        }
    }
}

.payment-icon {
    max-height: rem-calc(40px);
}

.payment-method {
    background-color: rgba(get-color(primary), 0.06);
    padding: 1rem 0.5rem;
    margin-bottom: 0.3rem;

    label {
        margin-left: 0;
    }
}

.discount-code {
    margin-top: 1rem;

    .field {
        height: 100%;
        .control {
            height: 100%;
            input#discount-code {
                height: 100%;
            }

        }
    }

    button {
        transform: translateX(5px);
    }
}
