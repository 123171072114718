$scout-guide-callout-background-primary: #C1E4FE;
$scout-guide-callout-background-secondary: #DEF4D4;

section.guides {
    display: grid;
    gap: 1.5rem;

    @media (min-width: 460px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
    }

    @include breakpoint(medium) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include breakpoint(large) {
        gap: 3rem;
    }

    article {
        border-radius: 0.6rem;
        box-shadow: 0 0 2px 2px rgba(80, 80, 80, 0.2);
        overflow: hidden;

        .scout-guide-image-container {
            overflow-y: hidden;
            max-height: rem-calc(400px);

            img {
                min-width: 100%;
            }
        }

        footer {
            padding: 0.6rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .icon {
                background: get-color(primary);
                border-radius: 50%;
                color: get-color(white);
                font-size: 1.2rem;
                font-weight: 700;
                padding: 0.6rem;
            }
        }
    }
}

.scout-guide {
    &.callout {
        color: get-color(black);
        border-color: transparent;

        &.primary {
            background-color: $scout-guide-callout-background-primary;
        }

        &.secondary {
            background-color: $scout-guide-callout-background-secondary;
        }
    }

    & ul {
        & li {
            & p {
                margin-bottom: 0;
            }
        }
    }
}
