.campaign-wrapper {
    .hero.campaign {
        height: 100vw;
        max-height: unset;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        @include breakpoint(medium) {
            height: 100%;
        }

        .text-holder {
            bottom: 2rem;
            left: 50%;
            right: unset;
            transform: translateX(-50%);

            .scout-button {
                position: unset;
            }
        }
    }

    .product-item-container:nth-child(odd) .product-item-info {
        margin-right: 0.5rem;
    }

    .product-item-container:nth-child(even) .product-item-info {
        margin-left: 0.5rem;
    }

    .product-item-details {
        margin: 0.5rem 0;
    }

    .product-image-wrapper.campaign {
        margin-bottom: 1rem;

        img {
            width: 100%;
        }
    }
}

