.block-dashboard-addresses {
    .block-content {
        @include breakpoint(medium) {
            display: grid;
            gap: 1rem;
            grid-template-columns: repeat(2, 1fr);
        }

        @import '../customer-info-box';
    }
}
