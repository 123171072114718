.three-images-container {
    display: block;

    @include breakpoint(small only) {
        margin-bottom: 0rem;
    }

    @include breakpoint(medium) {
        display: grid;
        column-gap: 1rem;
        grid-template-rows: 1fr;
    }

    .three-image {
        position: relative;
        margin-bottom: 0;
        grid-row: 1;
        display: grid;
        grid-template-rows: 1fr;

        @include breakpoint(small only) {
            margin-bottom: 1.5rem;
        }

        img {
            min-width: 100%;
            min-height: 100%;
            object-fit: cover;
        }

        .scout-button {
            position: absolute;
            bottom: 2rem;
            left: 50%;
            transform: translateX(-50%);
        }

        &-link {
            text-align: center;
            margin: 1.5rem 0 0;
            font-size: 1.25rem;
        }
    }
}
