.columns {
    display: grid;
    gap: 1rem;

    .account-nav {
        order: 2;

        @include breakpoint(large) {
            order: unset;
            padding: 0;
        }
    }

    .main {
        padding: 0 1rem;
    }

    @include breakpoint(large) {
        gap: 2rem;
        grid-template-columns: auto 1fr;
    }
}
