$iconDiameter: 100px;
$iconMaxSize: 50px;
$navDiameter: 34px;

.block-title {
    color: $primary-color;
    font-size: 1.5rem;
    margin: 1rem 0;
}

.age-groups {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 0;
    justify-content: center;

    a {
        display: contents;

        .age-group {
            min-width: calc(#{$iconDiameter} + 1rem);
            display: grid;
            justify-items: center;
            grid-template-rows: $iconDiameter auto;
            padding: 0.5rem;
            width: calc(100% / 3);
            margin: 0 auto;

            @include breakpoint(medium) {
                width: calc(100% / 6);
            }

            @include breakpoint(large) {
                width: calc(100% / 9);
            }

            &-image {
                width: $iconDiameter;
                height: $iconDiameter;
                display: grid;
                place-content: center;
                mask-image: url("../images/blob.svg");
                mask-size: cover;

                img {
                    max-height: $iconMaxSize;
                    max-width: $iconMaxSize;
                }
            }

            &-text {
                text-align: center;
                text-wrap: balance;
                margin-top: 0.5rem;
                color: $primary-color;
            }
        }
    }

    &.owl-carousel {
        .owl-nav {
            position: absolute;
            top: 50%;
            transform: translateY(-100%);
            left: 0;
            right: 0;
            display: flex;
            justify-content: space-between;

            button[class*=owl-] {
                background: $white;
                border: 1px solid $light-gray;
                border-radius: 50%;
                box-sizing: border-box;
                height: $navDiameter;
                width: $navDiameter;
                padding: 0;
                line-height: 0;
                text-align: center;
                transition: .3s ease;

                &.owl-prev:before {
                    @include scout-icon($icon-chevron-left, $fontSize: rem-calc(8), $lineHeight: false);
                    line-height: 0;
                }

                &.owl-next:before {
                    @include scout-icon($icon-chevron-right, $fontSize: rem-calc(8), $lineHeight: false);
                    line-height: 0;
                }

                i {
                    display: none;
                }
            }
        }
    }
}
