/* Menu */
.nav-sections {
    @include breakpoint(small only) {
        background: rgba(3, 19, 33, 0.8);
        bottom: 0;
        left: -100%;
        position: fixed;
        right: 100%;
        top: 6.6rem;
        z-index: 10;

        #store\.links {
            position: absolute;
            bottom: 0;
            background: $color-polar;
            width: 100%;
            margin: 0;
            border-top: 1px solid $border-color;
            .header.links {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: rem-calc(80px);
                margin: 0;
                padding: 0 rem-calc(10px);
            }
            li {

            }

            a {
                display: block;
                padding: rem-calc(8px) 0;
                color: $menu-item-color;
                font-size: rem-calc(14px);
                font-weight: $subheader-font-weight;
            }

        }

        .nav-sections-items {
            background: $white;
            bottom: 0;
            left: -100%;
            position: absolute;
            right: 100%;
            top: 0;
            transition: all .3s;
            z-index: 11;

            ul {
                left: 0 !important;
                top: 0 !important;
            }
        }

        .nav-open & {
            left: 0;
            right: 0;

            .nav-sections-items {
                left: 0;
                right: 20%;
            }
        }
    }

    @include breakpoint(medium) {
        background: get-color(primaryDark);

        .header.links {
            display: none;
        }
    }

    .section {
        &-item {
            &-title {
                display: none;
            }
        }
    }

    nav.navigation {
        ul {
            @include menu-direction(vertical);
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                &.level0 {
                    border-bottom: 1px solid $menu-item-border-color;
                }

                a {
                    color: $menu-item-color;
                    cursor: pointer;
                    display: block;
                    font-size: 15px;
                    line-height: 1.5;
                    padding: 9px 30px 7px 10px;
                    position: relative;
                }

                &.all-category {
                    a {
                        text-decoration: underline;
                    }
                }

                &.active {
                    a {
                        color: $menu-item-color-active;
                        font-weight: $subheader-font-weight;
                    }
                }

                &:last-child {
                    border-bottom: none;
                }
            }

            ul {
                background-color: $color-polar;
                border-top: 1px solid $menu-item-border-color;

                @include breakpoint(large) {
                    opacity: 0;
                    position: absolute;
                    transition: all 0.2s ease-in-out;
                    z-index: 10;
                }

                li {
                    a {
                        padding-left: 10px;
                    }

                    .ui-icon {
                        position: relative;
                        display: block;
                        float: right;
                        top: 5px;
                        right: -10px;

                        svg path {
                            fill: $black;
                        }
                    }

                    a {
                        color: $menu-item-color;
                        display: block;
                    }

                    ul {
                        li {
                            width: 50%;
                        }

                        width: 66.68%;
                        left: 33.34%;
                        top: 0;
                        background: $almost-white;
                        z-index: 11;
                        border-left: 1px solid #CFD3DC;
                        box-shadow: inset 8px 0 5px 0 rgba(0, 0, 0, 0.05);

                        ul {
                            left: 50%;
                            width: 50%;
                            z-index: 12;
                            box-shadow: none;

                            li {
                                width: 100%;
                            }
                        }
                    }
                }

                .image-wrapper {
                    max-width: 50%;
                    height: 350px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    overflow: hidden;
                }
            }

            li:hover {
                > ul.submenu {
                    visibility: visible;
                    opacity: 1;
                }
            }

            @include breakpoint(medium) {
                @include xy-grid-container();
                @include xy-grid;

                ul {
                    background: $white;
                    box-shadow: $menu-desktop-shadow;
                    border-top: none;
                    flex-flow: column;
                    min-width: 300px;
                    padding-left: 0;
                    padding-right: 0;

                    li {
                        border-bottom: 1px solid $light-gray;

                        &:last-child {
                            border-bottom: none;
                        }

                        &.active,
                        &:hover {
                            background: $menu-item-desktop-background-active;

                            a {
                                color: $menu-item-desktop-color-active;
                            }
                        }
                    }
                }

                li {
                    &.level-top {
                        border-bottom: none;

                        > a {
                            color: $white;
                            font-size: 16px;
                            font-weight: $subheader-font-weight;
                            padding: 18px 15px 15px;
                        }
                    }

                    &.level1 {
                        a {
                            color: $menu-item-desktop-color;
                            padding-left: rem-calc(14);
                            width: 100%;
                        }
                    }

                    &.parent {
                        > a {
                            padding-right: 30px;
                        }
                    }
                }

                .level-top {
                    &.parent {
                        > a {
                            &:after {
                                color: $white;
                                content: $icon-caret-down;
                                font-size: rem-calc(6);
                                right: 10px;
                            }

                            &.ui-state-focus,
                            &.ui-state-active {
                                background: $white;
                                color: get-color(cta);

                                &:after {
                                    color: get-color(cta);
                                    transform: translateY(-50%) rotate(180deg);
                                }
                            }
                        }
                    }
                }
            }
        }

        .level0.submenu .parent::after {
            content: "";
            display: inline-block;
            float: right;
            margin-top: 8px;
            margin-right: 10px;
            width: 20px;
            height: 20px;
            background-position: center;
        }

        ul ul li a {
            display: inline-block !important;
        }

    }

    .level-top.parent > a {
        padding-right: 1.2rem;
        &:after {
            @include scout-icon($icon: $icon-chevron-down, $fontSize: 12px);
            color: $medium-gray;
            display: block;
            position: absolute;
            right: 15px;
            top: 50%;
            transition: transform .3s;
            transform: translateY(-50%);
        }
    }

    // The idle state style, until the navigation is loaded and ready to be used
    .level0.submenu {
        display: none;
        position: relative;

        @include breakpoint(medium) {
            position: absolute;
        }
    }

}
