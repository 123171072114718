.block-dashboard-info {
    order: 1;

    * {
        text-align: center;
    }

    .email,
    .newsletter-subscription-status,
    .edit,
    .logout-button-box {
        margin-bottom: 0.5rem;
    }

    .email {
        .label {
            margin: 0 0.5rem 0 0;
            padding: 0.25rem 0.5rem;
            color: get-color(white);
            background: $medium-gray;
            font-weight: 500;
        }
    }

    .newsletter-subscription-status {
        .status {
            &:before {
                color: get-color(white);
                background: $medium-gray;
                padding: 0.4rem;
                border-radius: 50%;
                font-size: 0.5rem;
                font-weight: bold;
                vertical-align: middle;
            }
        }
    }

    .edit {
        display: inline-block;
    }

    .logout-button-box {
        margin: 0;
        list-style: none;
    }
}
