.page-main {
    header {
        font-size: 2rem;
        font-weight: 500;
        line-height: 1.25;
        padding-bottom: 1rem;
    }

    .block-dashboard-addresses,
    .block-dashboard-orders {
        display: none;
    }

    .block-title {
        text-align: center;
        font-weight: 500;
        margin-bottom: 0.5rem;

        strong {
            font-size: 1.5rem;
        }
    }

    @import 'customer-dashboard-info';
    @import 'customer-dashboard-addresses';
    @import 'customer-dashboard-orders';

    .columns .column .block:not(:last-child) {
        margin-bottom: 1rem;
        @include breakpoint(large) {
            margin-bottom: 3rem;
        }
    }

    .block-addresses-default {
        .box {
            margin-bottom: rem-calc(20px);
        }
    }

    .columns .main .block-content .box .box-actions {
        a {
            display: inline;
        }
        a:after {
            content: "\a";
            white-space: pre;
        }
    }

    @include breakpoint(medium) {
        .block-dashboard-addresses,
        .block-dashboard-orders {
            display: block;
        }
    }
}
