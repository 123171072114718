.top {
    row-gap: 1.5rem;

    .cell {
        .hero {
            margin-bottom: 0rem;

            .scout-button {
                position: absolute;
                bottom: 2rem;
                left: 2rem;
            }
        }

    }
}
