.table-caption {
    padding: 0 0 1rem;
    font-size: 2rem;
}

table {
    tr {
        .shipping,
        .id {
            display: none
        }

        @include breakpoint(medium) {
            .shipping,
            .id {
                display: table-cell;
            }
        }
    }
}
