@import '../../components/scout-guides.scss';

.video-player {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
}
