#footerwarehouselist,
#storestock-modal {
  display: none;
}

.store-selector-modal .modal-inner-wrap {
  @include breakpoint(medium) {
    width: 640px;
  }
}

.store-selector-modal__storelist {
  list-style-type: none;
  margin-left: 0;
  margin-bottom: 0;
}

.store-selector-modal__row {
  @include xy-grid();
  padding-bottom: 5px;
  margin-bottom: 5px;

  &:after {
    content: "";
    width: 100%;
    border-bottom: 1px solid $light-gray;
    margin-top: 1rem;
    margin-bottom: 0.25rem;

    @include breakpoint(large) {
      margin-left: rem-calc(map-get($grid-column-gutter, small));
      margin-right: rem-calc(map-get($grid-column-gutter, small));
    }
  }

  @include breakpoint(large) {
    @include xy-gutters($negative: true);
  }

  .store-selector-modal__store-name-status-wrapper {
    @include xy-cell(8.5, $breakpoint: small);
    margin-left: 0;
    padding-top: 6px;

    .store-selector-modal__storename {}
    .store-selector-modal__storestatus {}

    @include breakpoint(large) {
      @include xy-cell(9);
      @include xy-grid();
      .store-selector-modal__storename {
        @include xy-cell(8);
        margin-left: 0;
      }
      .store-selector-modal__storestatus {
        @include xy-cell(5, $gutter-position: right);
      }
    }
  }

  .store-selector-modal__storename {
    @include text-truncate();
    @include breakpoint(large) {
      width: 70%;
    }
  }

  .store-selector-modal__select-store-button {
    @include xy-cell(3.5, $breakpoint: small);
    @include button-lybe(false, get-color(primary), small, $color: get-color(white));

    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 0;
    margin-left: auto;

    @include breakpoint(large) {
      @include xy-cell(3);
    }
  }
  &:last-child {
    border-bottom: none;
  }
}
