.modal-popup,
.modal-slide {
    z-index: 999 !important;
    bottom: 0;
    left: 0;
    min-width: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;

    &._show {
        visibility: visible;

        .modal-inner-wrap {
            transform: translate(0, 0);
        }
    }

    @import 'modal-overlay';

    .modal-inner-wrap {
        background-color: #fff;
        box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35);
        opacity: 1;
        pointer-events: auto;

        @import 'modal-header';

        @import 'modal-content';

        @import 'modal-footer';

    }
}

.modal-slide {
    left: rem-calc(44px);

    .modal-inner-wrap {
        height: 100%;
        overflow-y: auto;
        position: static;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        transition-duration: 0.3s;
        -webkit-transition-property: -webkit-transform, visibility;
        transition-property: transform, visibility;
        transition-timing-function: ease-in-out;
        width: auto;
    }

    &._show {
        .modal-inner-wrap {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    }

    &._inner-scroll {
        .modal-inner-wrap {
            overflow-y: visible;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column
        };
    }
}


//REFACTOR BELOW

.modal-slide._inner-scroll .modal-header,
.modal-slide._inner-scroll .modal-footer {
  -webkit-flex-grow: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0
}

.modal-slide._inner-scroll .modal-content {
  overflow-y: auto
}

.modal-popup {
  left: 0;
  overflow-y: auto
}

.modal-popup._show .modal-inner-wrap {
  -webkit-transform: translateY(0);
  transform: translateY(0)
}

.modal-popup .modal-inner-wrap {
  padding: 1rem;
  margin: 5rem auto;
  width: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  box-sizing: border-box;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  -webkit-transform: translateY(-200%);
  transform: translateY(-200%);
  transition-duration: 0.2s;
  -webkit-transition-property: -webkit-transform, visibility;
  transition-property: transform, visibility;
  transition-timing-function: ease
}

.modal-popup .modal-inner-wrap {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  width: calc(100% - 1rem);

  @include breakpoint(medium) {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    max-width: 36.25rem;
  }
}

.modal-popup._inner-scroll {
  overflow-y: visible
}

.ie10 .modal-popup._inner-scroll,
.ie9 .modal-popup._inner-scroll {
  overflow-y: auto;
}

.modal-popup._inner-scroll .modal-inner-wrap {
  max-height: 90%;
}

.ie10 .modal-popup._inner-scroll .modal-inner-wrap,
.ie9 .modal-popup._inner-scroll .modal-inner-wrap {
  max-height: none;
}

.modal-popup._inner-scroll .modal-content {
  overflow-y: auto;
}

.modal-popup .modal-content {
  font-size: 0.875rem;
}

.modal-popup .modal-footer-actions {
  text-align: right;
}

.modal-custom .action-close,
.modal-popup .action-close,
.modal-slide .action-close {
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-shadow: none;
  font-weight: 400;
  display: inline-block;
  text-decoration: none;
  position: absolute;
  right: 0;
  top: 0;
  &:hover {
    box-shadow: none;
  }
}

.modal-custom .action-close:focus,
.modal-popup .action-close:focus,
.modal-slide .action-close:focus,
.modal-custom .action-close:active,
.modal-popup .action-close:active,
.modal-slide .action-close:active {
  background: none;
  border: none;
}

.modal-custom .action-close:hover,
.modal-popup .action-close:hover,
.modal-slide .action-close:hover {
  background: none;
  border: none;
}

.modal-custom .action-close.disabled,
.modal-popup .action-close.disabled,
.modal-slide .action-close.disabled,
.modal-custom .action-close[disabled],
.modal-popup .action-close[disabled],
.modal-slide .action-close[disabled],
fieldset[disabled] .modal-custom .action-close,
fieldset[disabled] .modal-popup .action-close,
fieldset[disabled] .modal-slide .action-close {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.modal-custom .action-close > span,
.modal-popup .action-close > span,
.modal-slide .action-close > span {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.modal-custom .action-close:before,
.modal-popup .action-close:before,
.modal-slide .action-close:before {
  -webkit-font-smoothing: antialiased;
  font-size: 32px;
  line-height: 32px;
  color: $dark-gray;
  content: '×';
  margin: 0;
  vertical-align: top;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  speak: none;
  text-align: center;
}

.modal-custom .action-close:hover:before,
.modal-popup .action-close:hover:before,
.modal-slide .action-close:hover:before {
  color: inherit;
}

.modal-custom .action-close:active:before,
.modal-popup .action-close:active:before,
.modal-slide .action-close:active:before {
  color: inherit;
}

.modal-custom .action-close:hover:before,
.modal-popup .action-close:hover:before,
.modal-slide .action-close:hover:before {
  color: $black;
}

.modal-custom .action-close {
  margin: 25px;
}

.modal-popup .action-close {
  padding: 10px;
}

.modal-slide .action-close {
  padding: 1.1rem 2rem;
}

.modal-slide .page-main-actions {
  margin-bottom: -12.9rem;
  margin-top: 2.1rem;
}

@media screen and (min-width: 768px) { /*include 769px ?*/
  .modal-popup--wide .modal-inner-wrap {
      width: 90%;
  }
}

._has-modal {
  overflow-y: hidden;
}
