.title {
    border-bottom: 2px solid $medium-gray;

    @include breakpoint(medium) {
        @include xy-cell(12);
    }

    @include breakpoint(large) {
        font-size: 2rem;
    }
}

.block.shipping {
    display: none;
}

.cart-totals,
.block.discount {
    @include breakpoint(medium) {
        @include xy-cell(6);
    }

    @include breakpoint(large) {
        @include xy-cell(12);
    }
}

.checkout-methods-items {
    list-style: none;
    margin: 1rem 0 0;

    button {
        @include button-lybe($size: large);

        @include breakpoint(small only) {
            @include button-lybe($expand: true);
            margin-bottom: 0;
        }
    }

    @include breakpoint(medium only) {
        @include xy-cell(12);
        @include xy-grid();
        @include flex-align(right);

        margin-top: 0;

        .item {
            top: 100%;
            transform: translateY(-100%);
        }
    }

    @include breakpoint(large) {
        @include xy-cell(12);
        @include xy-grid();
        @include flex-align(right);

    }

}
