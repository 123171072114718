/**
* Notice on reading this override file
*
* The selector specificity in `firecheckout.css` is not well done
* it is to specific. This makes it really hard to override without creating hard to read Sass.
*/
.firecheckout {
    .page-header {
        @import 'checkout-page-header';
    }

    .page-title-wrapper {
        @import 'checkout-page-header-row';
    }

    #checkout.checkout-container {
        @import 'checkout-contianer';

        .checkout-shipping-address {
            @import 'checkout-step-shipping-adderss';
        }

        .checkout-shipping-method {
            @import 'checkout-step-shipping-method';
        }

        .checkout-payment-method {
            @import 'checkout-step-payment-method';
        }

        .checkout-billing-address {
            @import 'checkout-billing-address';
        }

        .opc-summary-wrapper {
            @import 'checkout-summary';
        }
    }
}
