.minicart-wrapper {
    .action {
        .icon-bag {
            font-size: 30px;

            .path {
                &1, &2, &3 {
                    &:before {
                        transition: color .3s;
                    }
                }
            }
        }

        &:hover,
        &.active {
            .icon-bag {
                .path {
                    &1,
                    &3 {
                        &:before {
                            color: $color-orange;
                        }
                    }
                }
            }
        }
        &:after {
            clear: both;
            content: " ";
            display: block;
        }
    }
    .counter {
        .counter-number {
            background-color: $color-orange;
            border-radius: 50%;
            display: block;
            font-size: 10px;
            font-weight: bold;
            height: 14px;
            line-height: 14px;
            min-width: 14px;
            position: absolute;
            padding: 1px;
            right: -3px;
            text-align: center;
            text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
            top: 10px;

            @include breakpoint(medium) {
                font-size: 15px;
                height: 20px;
                line-height: 20px;
                min-width: 20px;
                padding: 2px;
                right: -8px;
                top: -5px;
            }
        }
        .counter-label { display: none; }

      &.empty {
        visibility: hidden;
      }
    }

    .price-container.amount {
        display: none;
    }

    @include breakpoint(small only) {
        right: 1.25rem;
    }

    @include breakpoint(medium) {
        @include xy-cell(3);
        order: 3;
        position: relative;
        top: 0;

        a {
            color: #fff;
        }

        .icon-bag {
            display: block;
            float: right;
        }

        .showcart {
            align-items: center;
            display: flex;
            flex-flow: row;
            padding: 0;

            .price-container {
                display: block;
                flex-grow: 1;
                padding-right: 12px;
                text-align: right;
            }

            .text {
                display: block;
                line-height: rem-calc(22);
                font-size: rem-calc(16);
                font-weight: $global-weight-bold;
                text-transform: uppercase;
                .vat {
                    font-size: .688rem;
                    color: #faba80;
                    font-weight: normal;
                }
            }

            .price {
                font-size: rem-calc(18);
                line-height: rem-calc(22);
            }
        }

        .price-container {
            display: block;
        }
    }

    .quick-order-link {
        &.icon-custom {
            a {
                &:before {
                    content: '';
                    display: inline-block;
                    position: relative;
                    background-image: url("../../images/icons/toc-white.svg"); //TODO cleanup; @farouk. This should to be declared inline (due to diffrent paths foundation <> magento), same goes for all images in CSS. Issue with this one is that we do not have a proper html space to put this in in Magento... We will figure this out.
                    background-size: cover;
                    height: 16px;
                    width: 16px;
                    margin-right: 5px;
                    vertical-align: middle;
                }
            }
        }
    }
}
