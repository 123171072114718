.hero-full-width {
    position: relative;
    width: 100%;

    a {
        display: contents;
    }

    &.fifty-fifty {
        display: grid;

        @include breakpoint(medium) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &-image {
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
        max-height: var(--maxHeight);
    }

    .scout-button {
        position: absolute;
        bottom: 2rem;
        left: 2rem;

        @include breakpoint(large) {
            bottom: 4rem;
            left: 4rem;
        }
    }

    &-fifty-fifty {
        background: $primary-color;
        display: flex;
        align-items: flex-end;

        &-content {
            padding: 2rem;

            @include breakpoint(medium) {
                max-width: 500px;
                margin: 2rem auto;
                text-align: center;
                padding: 2rem 1rem;
            }

            @include breakpoint(large) {
                margin: 3rem auto;
                padding: 2rem;
            }

            h2 {
                color: $white;
                font-size: 2rem;
            }

            p {
                color: $white;
                font-size: 1.5rem;
                font-weight: lighter;
                margin-bottom: 2rem;
            }

            .scout-button {
                position: initial;

                @include breakpoint(small only) {
                    width: auto;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                }
            }
        }
    }
}
