.cms-index-index {
    .page-main {
        background: $body-background;
    }
    .hero {
        margin-bottom: rem-calc(map-get($grid-column-gutter, small) / 2);
        overflow: hidden;
        position: relative;

        @include breakpoint(medium up) {
            max-height: 28vw;
        }

        .text-holder {
            bottom: rem-calc(map-get($grid-column-gutter, small) / 2);
            left: rem-calc(map-get($grid-column-gutter, small) / 2);
            position: absolute;
            right: rem-calc(map-get($grid-column-gutter, small) / 2);
            top: auto;
            z-index: 1;

            h2,
            h3 {
                background: $primary-color;
                color: $white;
                display: inline-block;
                line-height: 1;
            }

            h2 {
                font-size: rem-calc(22);
                font-weight: $subheader-font-weight;
                padding: rem-calc(10) rem-calc(15);
            }

            h3 {
                font-size: rem-calc(14);
                font-weight: 400;
                padding: rem-calc(10) rem-calc(15);
            }

            @include breakpoint(medium up) {
                bottom: rem-calc(map-get($grid-column-gutter, medium));
                left: rem-calc(map-get($grid-column-gutter, medium));
                right: rem-calc(map-get($grid-column-gutter, medium));

                h2 {
                    font-size: rem-calc(48);
                }

                h3 {
                    font-size: rem-calc(20);
                }
            }
        }
    }

    .stretch-items {
        @include breakpoint(medium up) {
            align-content: stretch;
            align-items: stretch;
            height: calc(100% + 2rem);
        }
    }

    .banner {
        position: relative;

        &.fixed-height {
            overflow: hidden;

            img {
                left: 50%;
                max-width: initial;
                position: relative;
                transform: translateX(-50%);
            }
        }

        .text-holder {
            color: $white;
            padding: rem-calc(map-get($grid-column-gutter, small) / 2);
            position: absolute;

            &.bottom {
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
                bottom: 0;
                left: 0;
                right: 0;
                top: auto;
            }

            &.top {
                bottom: auto;
                left: 0;
                right: 0;
                top: 0;
            }

            &.left {
                bottom: auto;
                left: 0;
                right: auto;
                top: 50%;
                transform: translateY(-50%);
            }

            &.full {
                align-content: center;
                align-items: center;
                display: flex;
                bottom: 0;
                flex-flow: row;
                left: 0;
                right: 0;
                top: 0;
            }

            .icon {
                display: none;
            }

            .heading {
                font-size: rem-calc(20);
            }

            strong {
                font-size: rem-calc(16);
                font-weight: $global-weight-bold;
            }

            p {
                font-size: rem-calc(14);
                margin-bottom: 0;
            }
        }

        @include breakpoint(medium up) {
            height: 100%;

            &.autoposition {
                overflow: hidden;

                img {
                    left: 50%;
                    max-width: initial;
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%) translateX(-50%);
                }
            }

            .text-holder {
                padding: rem-calc(map-get($grid-column-gutter, medium) / 2);

                &.top {
                    &-left {
                        right: 50%;
                    }
                    &-right {
                        left: 50%;
                    }
                }

                &.left {
                    right: 50%;
                }

                &.right {
                    left: 50%;
                }
            }

            .heading {
                font-size: rem-calc(48);
                font-weight: $global-weight-bold;
            }
        }

        @include breakpoint(large up) {
            .text-holder {
                .icon {
                    display: block;
                    font-size: rem-calc(64);
                    margin: 0 rem-calc(map-get($grid-column-gutter, medium));
                    text-align: center;

                    i {
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
            }
        }
    }

    .group {
        align-content: stretch;
        align-items: stretch;
        color: $white;
        display: flex;
        flex-flow: column;
        font-weight: $global-weight-bold;
        height: 100%;
        line-height: 1.25;
        padding: rem-calc(20);
        text-align: center;

        &-image {
            display: inline-block;
            height: 36px;
            margin-bottom: 1rem;
            position: relative;
        }

        &-green {
            background: $color-green;
        }
        &-blue {
            background: $color-blue;
        }
        &-orange {
            background: $color-orange;
        }
        &-fuchsia {
            background: $color-fuchsia;
        }
        &-yellow {
            background: $color-yellow;
            color: $color-darkyellow;
        }
        &-primary {
            background: $primary-color;
        }
        &-purple {
            background: $color-purple;
        }
        &-matteblue {
            background: $color-matteblue;
        }

        @include breakpoint(medium up) {
            align-content: center;
            align-items: center;
            flex-flow: row;
            padding: 1rem;

            &-image {
                margin-bottom: 0;
                margin-right: 1rem;
                width: 36px;
                text-align: center;
            }
        }

        @media (max-width: 321px) {
            // This is for iPhone 5 and other very small screens
            font-size: rem-calc(12);
        }
    }

    .products-grid {
        .product-items {
            @include breakpoint(small only) {
                @include product-card('grid', 3);
            }

            @include breakpoint(medium up) {
                @include product-card('grid', 6);
            }
        }
    }

    .block-products-list {
        .block-title {
            color: $primary-color;
            font-size: rem-calc(24);
            margin-bottom: 1rem;

            strong {
                font-weight: $global-weight-bold;
            }
        }
        .block-content {
            @include xy-grid;
            @include xy-gutters($negative: true);
        }
    }

    .margin-bottom {
        margin-bottom: 2rem;

        &:last-child {
            margin-bottom: 0;
        }

        &-0 {
            margin-bottom: 0;
        }
    }
}
