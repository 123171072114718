// Due to configurability:
// The layout of the shipping address section varies depending on whether the customer
// has an address prefilled or not hence this complex selector.
.step-content > * + .form-shipping-address {
    margin-top: 0.5rem;
}

.shipping-address-items {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-basis: auto;
    flex-flow: row wrap;
    justify-content: flex-start;

    margin-bottom: 0;

    // reset
    @include breakpoint(medium) {
        max-height: unset !important;
        padding-right: 0 !important;
        margin-right: 0 !important;
    }

    .shipping-address-item {
        @extend .card;
        overflow: unset;
        width: 100% !important; // override deep selector of Fire Checkout
        margin: 1.25rem 0;
        padding: 1.5rem;
        flex-grow: 0;

        &.selected-item {
            background-color: rgba(get-color(primary), 0.06);
        }

        &.selected-item {
            border: 1px solid $primary-color;
        }
    }

    @include breakpoint(large up) {
        .shipping-address-item {
            width: calc(50% - 2.5rem);
        }
    }
}

.action-select-shipping-item {
    @include button-lybe($expand: true, $style: hollow);
    position: relative;
    right: 0;
    top: 0;
    margin-top: 0.5rem;

    @include breakpoint(large) {
        margin-top: 1rem;
    }
}

button.fc-compact-address-button {
    @include button-lybe($expand: true);
}

button.edit-address-link {
    width: 100%;
}

.action-show-popup {
   @include button-lybe($expand: true);
}
