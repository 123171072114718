.captcha {
    background: none;
    input {
        margin-bottom: 0;
    }

    .mage-error:not(input) {
        background: $almost-white;
        position: relative;
        z-index: 1;
        padding-top: 1rem;
        &:before {
            content: '';
            display: block;
            position: absolute;
            background: $almost-white;
            line-height: 1;
            height: 100%;
            width: 100%;
            left: -2rem;
            opacity: 1;
            top: 0;
            z-index: -1;
        }
    }

    .captcha-image {
        background: none;
        @include breakpoint(medium) {
            margin-top: 0.5rem;
        }

        button {
            @include button-lybe($expand: true, $size: small, $style: hollow);

            @include breakpoint(large) {
                @include button-lybe($style: hollow);
            }
        }

        @include breakpoint(small only) {
            text-align: center;
            .image-container {
                background: $almost-white;
                padding: 5vw 0;
            }
        }

        @include breakpoint(medium) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 0.5rem;
        }
    }
}
