.page-main {
    .login-container {
        display: grid;
        gap: 1rem;

        @include breakpoint(medium) {
            grid-template-columns: repeat(2, 1fr);
        }

        .form-login {
            @import 'customer-form-login';
        }
    }
}
