input[type="checkbox"] + label {
    display: inline-block;
    vertical-align: top !important;

    .extra-information {
        display: block;
        font-style: italic;
        font-weight: $subheader-font-weight;
    }
}
