.cart-totals {
    min-height: 120px;

    table.totals {
        th,
        td {
            font-weight: normal;
            padding: 0.5rem 0 0.5rem;
        }

        tbody,
        tr {
            font-size: 1rem;
            border: none;
        }

        th {
            text-align: left;
        }

        td {
            text-align: right;
        }

        .table-caption {
            display: none;
        }
    }
    .grand.totals {
        th,
        td {
            font-weight: bold;
        }
    }
}
