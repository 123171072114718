@font-face {
    font-family: 'ITCFranklinGothic';
    src:
        url('../fonts/itcfranklingothic/ITCFranklinGothicBook.ttf') format('truetype'),
        url('../fonts/itcfranklingothic/ITCFranklinGothicBook.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ITCFranklinGothic-Cd';
    src:
        url('../fonts/itcfranklingothic/ITCFranklinGothicBookCondensed.ttf') format('truetype'),
        url('../fonts/itcfranklingothic/ITCFranklinGothicBookCondensed.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ITCFranklinGothic';
    src:
        url('../fonts/itcfranklingothic/ITCFranklinGothicMedium.ttf') format('truetype'),
        url('../fonts/itcfranklingothic/ITCFranklinGothicMedium.woff') format('woff');
    font-weight: $global-weight-bold;
    font-style: normal;
}

@font-face {
    font-family: 'ITCFranklinGothic-Cd';
    src:
        url('../fonts/itcfranklingothic/ITCFranklinGothicMediumCondensed.ttf') format('truetype'),
        url('../fonts/itcfranklingothic/ITCFranklinGothicMediumCondensed.woff') format('woff');
    font-weight: $global-weight-bold;
    font-style: normal;
}
