.products-grid {
    .owl-carousel {
        .owl {
            &-prev,
            &-next {
                background-color: transparent;
                box-shadow: none;
                bottom: 0;
                margin: 0;
                position: absolute;
                top: 0;

                i {
                    background: get-color(primary);
                    color: $white;
                    padding: 20px;
                }
            }

            &-prev {
                background-image: linear-gradient(to left, transparent, #ffffff);
                left: 0;
                padding: 0 40px 0 0;
            }
            &-next {
                background-image: linear-gradient(to right, transparent, #ffffff);
                right: 0;
                padding: 0 0 0 40px;
            }
        }
    }
}
