.form-create-account {
    @include breakpoint(medium) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }

    .info {
        @include breakpoint(medium) {
            grid-column: 1/3;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1rem;
        }

        .newsletter {
            @include breakpoint(medium) {
                grid-column: 1/3;
            }

            @include breakpoint(large) {
                grid-column: 1/2;
            }
        }
    }

    .scout-group {
        @include breakpoint(medium) {
            grid-row: 2;
            grid-column: 2/3;
        }
    }

    .actions-toolbar {
        @include breakpoint(medium) {
            align-self: end;
            justify-self: right;
        }
    }

}
