//
// @TODO: Add docs to price!
//
@mixin price() {
    .price-box {
        color: $color-orange;
        font-weight: $global-weight-bold;
        font-size: rem-calc(18);

        &.loading {
            background: transparent url('../images/loader-1.gif') center center no-repeat;
            background-size: auto 100%;
            color: transparent !important;
        }

        .old-price {
            color: $medium-gray;
            margin-right: 5px;

            .price {
                text-decoration: line-through;
            }
        }

        .price-label {
            @include hide;
        }

        .minimal-price {
            margin-bottom: 0;
        }
    }
}

//
// @TODO: Add docs to product-listing!
//
@mixin product-card($type: 'list', $columns: 4, $actions: true, $hr: false, $list-large: false) {
    @if $type == 'grid' {
        @include xy-grid;

        .product-item {
            @include xy-cell($grid-column-count/$columns);
            position: relative;
            margin-bottom: 20px;

            .product-item-actions {
                display: none;
                opacity: 0;
                z-index: 100;
                padding: 1rem 1.5rem;
            }

            .product-image-photo {
                margin-bottom: 10px;

                @include breakpoint(medium up) {
                    max-width: 100%;
                    margin-right: 0;
                }
            }

            .product-item-actions,
            .tocompare {
                display: none;
            }
        }
    } @else if $type == 'list' {
        .product-item {
            .product-item-info {
                @include xy-grid;
            }

            .product-item-photo {
                @include xy-cell(shrink, false);

                max-width: 60px;
                margin-right: 1.25rem;

                @include breakpoint(medium) {
                    max-width: 64px;
                }
            }

            .product-item-details {
                @include xy-cell(auto, false);
                @include text-truncate();
                text-align: left;
            }


            @if $list-large {
                .product-item-details {
                    @include xy-grid;
                    @include xy-grid-layout(2, '.product-item-name, .product-item-sku, .price-box, .product-item-inner');

                    .product-item-name {
                        order: 1;
                    }

                    .product-item-sku {
                        order: 3;
                    }

                    .price-box {
                        order: 2;
                    }

                    .product-item-inner {
                        order: 4;
                    }
                }

                .price-box,
                .product-item-inner {
                    text-align: right;
                }
            }

            @if $hr {
                padding-bottom: 10px;
                border-bottom: $hr-border;
                margin-bottom: 20px;

                &:last-child {
                    // unset?
                    padding-bottom: 0;
                    border-bottom: none;
                    margin-bottom: 0;
                }
            }
        }
    }

    @if $actions != true {
        .product-item-actions {
            display: none;
        }
    }
}

.products-grid,
.products-list {
    margin-bottom: 1.25rem;
}

.product-items {
    list-style: none;
    margin: 0;
    @include price;

    .product-item {
        &-details {
            font-size: rem-calc(16);
            line-height: 1.25;

            button {
                margin-top: 20px;
            }
        }

        &-name {
            display: block;
            font-weight: $global-weight-bold;

            a {
                color: get-color(cta);
            }
        }

        &-sku {
            font-size: rem-calc(12);
            font-weight: normal;
            color: $medium-gray;
        }
    }
}
