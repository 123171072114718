// Off canvas and menu stuff
.page-wrapper {
    @include off-canvas-wrapper;
}

.is-drilldown-submenu {
  @include menu-base;
}

// Offcanvas
.off-canvas {
  align-items: center;
  justify-content: center;
  padding-bottom: 60px;
  color: $black;
  .logo {
    padding: 0.875rem;
  }
  hr {
    margin: 0;
  }
  .small {
    font-size: 0.688rem;
    color: $dark-gray;
    a {
      color: $black;
    }
  }
  .vertical.menu {
    a {
      color: $black;
      padding: 0.725rem;
      border-bottom: 1px solid $light-gray;
    }
    .js-drilldown-back {
      a {
        background: #f5f5f5;
      }
    }
  }
  .tax-switcher-wrapper {
    display: flex;
    justify-content: center;
    @include tax-switcher();
  }
  .copyright {
    text-align: center;
    display: block;
  }
}

.position-left {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}
